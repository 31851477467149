import axios from "axios";

export const axiosInstance = axios.create({
    timeout: 20000, 
});

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
        // const token = localStorage.getItem('token');
        // if (token) {
        //   config.headers['Authorization'] = token;
        // }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    error => {
      Promise.reject(error)
    }
)

// Response interceptor
axiosInstance.interceptors.response.use(response => {
        return response
    },
    error => {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.replace('/login');
            return Promise.reject(error?.response?.data);
        }
        return Promise.reject(error?.response?.data);
    }
)


export const headers = () => {
    if (localStorage.getItem('token')) {
        const auth = localStorage.getItem('token');
        return {
          'Content-Type': 'application/json',
          'Authorization': auth
        };
    }
    return {
        'Content-Type': 'application/json'
    };
}

export const multipartHeader = () => {
    if (localStorage.getItem('token')) {
        const auth = localStorage.getItem('token');
        return {
          'Content-Type': 'multipart/form-data',
          'Authorization': auth
        };
    }
    return {
        'Content-Type': 'multipart/form-data'
    };
}