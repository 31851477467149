import React from 'react';
import { Col, Row } from 'reactstrap';

const LenderMappingBRE = ({data}) => {

    console.log(data, 'lender bre data')


    return (
        <>
            <Row className='p-4'>
                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                    <div className='data-key ms-4'>Product Scheme Code</div>
                    <div className='data-value me-4'>{data?.config?.productSchemeCode}</div>
                </div>
                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                    <div className='data-key ms-4'>Type of Vehicles</div>
                    <div className='data-value me-4'>{data?.config?.typeOfVehicle?.map((vehicle) => (<>{vehicle?.label},&nbsp;</>))}</div>
                </div>
                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                    <div className='data-key ms-4'>Type of Loan</div>
                    <div className='data-value me-4'>{data?.config?.typeOfLoan?.map((loan) => (<>{loan?.label},&nbsp;</>))}</div>
                </div>
                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                    <div className='data-key ms-4'>Type of Entity</div>
                    <div className='data-value me-4'>{data?.config?.typeOfEntity?.map((entity) => (<>{entity?.label},&nbsp;</>))}</div>
                </div>
                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                    <div className='data-key ms-4'>Type of Product</div>
                    <div className='data-value me-4'>{data?.config?.typeOfProduct?.map((product) => (<>{product?.label},&nbsp;</>))}</div>
                </div>
            </Row>
        </>
    )
}

export default LenderMappingBRE;
