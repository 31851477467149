import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Templates from '../../Pages/Campaign/Templates/Templates';
import Details from '../../Pages/Campaign/Templates/Details';

const Template = () => {
  return (
    <Routes>
        <Route path='/list' element={<Templates />} />
        <Route path='/details/:templateId' element={<Details />} />
    </Routes>
  )
}

export default Template;