import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../components/Dropdown/Dropdown";
import TextInput from "../../../components/text-input/TextInput";

const CreateLender = (props) => {
  const { adminServer, toaster } = useClass();
  const { isOpen, setIsOpen } = props;
  const [formData, setFormData] = useState({
    lenderName:'',
    lenderCode:'',
    panNumber:'',
    type:'',
  })
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleModelClose = () => {
    setIsOpen(false);
  };

  // handlefields
  const handleChange = (key, value) => {
    if(key ==="type"){
      setFormData((prevData) => ({
        ...prevData,
        [key]: value?.value
      }));
    }
    else{
      setFormData((prevData) => ({
        ...prevData,
        [key]: value
      }));
    }
   
  }

  const handleLenderCreation = async () => {
    if (validateForm()) {
      await createLender(formData);
    } else {
      toaster.show(true, "Please Fill All Details to create lender.");
    }
  };

  const createLender = async (payload) => {
    try {
      setLoading(true);
      const response = await adminServer.createLender(payload);
      const lenderCode = response?.data?.data?.code;
      setLoading(false);
      setIsOpen(false);
      navigate(`/lenders/details/${lenderCode}`);
    } catch (error) {
      toaster.show(true, error?.message);
    }
  };


  const validateForm = () => {
    return formData?.lenderName.trim() !== "" && 
    formData?.lenderCode.trim() !== "" 
    && formData?.type && formData?.panNumber !== "";
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Lender
          </h5>
          <button
            type="button"
            onClick={handleModelClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
             <TextInput
              label={'Lender Name'}
              name={'lenderName'}
              required={true}
              value={formData?.lenderName}
              handleInput={handleChange}
            />
          </div>
          <div className="mt-4">
            <TextInput
              label={'Lender Code'}
              name={'lenderCode'}
              required={true}
              value={formData?.lenderCode}
              handleInput={handleChange}
            />
          </div>
          <div className="mt-4">
            <TextInput
              label={'Pan Number'}
              name={'panNumber'}
              required={true}
              value={formData?.panNumber}
              handleInput={handleChange}
            />
          </div>
          <div className="mt-4">
            <Dropdown
              label={"Type"}
              value={formData?.type?.value}
              required={true}
              name={"type"}
              options={[{label: 'Lender', value: 'LENDER'}, {label: 'Insurer', value: 'INSURER'}]}
              handleInput={handleChange}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleModelClose}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleLenderCreation}
          >
            Save changes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreateLender;