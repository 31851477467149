import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RadialChart = ({ dashboardCampaignData }) => {
  
  const dataArray = [
    ...(dashboardCampaignData?.campaignsWithLeadCount || []),
    ...(dashboardCampaignData?.campaignsWithCustomerCount || []),
    ...(dashboardCampaignData?.campaignsWithCompletedJourney || []),
  ];

  const aggregatedData = dataArray.reduce((acc, { source, count }) => {
    const countNumber = parseInt(count, 10);
    acc[source] = (acc[source] || 0) + countNumber;
    return acc;
  }, {});

  const result = Object.entries(aggregatedData).map(([source, count]) => ({
    source,
    count: count.toString(),
  }));

  const series = result.length ? result.map(({ count }) => parseInt(count, 10)) : [0];
  const labels = result.length ? result.map(({ source }) => source) : ['No Data'];

  // Chart options
  const options = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -15,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 10,
          size: '45%',
        },
        track: {
          show: true,
          strokeWidth: '70%',
          margin: 12,
        },
        dataLabels: {
          name: {
            fontSize: '27px',
          },
          value: {
            fontSize: '20px',
          },
          total: {
            show: true,
            label: 'Total',
            formatter: () => {
              // Custom formatter for total
              return series.reduce((acc, value) => acc + value, 0);
            },
          },
        },
      },
    },
    labels: labels,
    colors: ['#099680', '#4aa3ff', '#5664d2'],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="350"
      className="apex-charts"
    />
  );
};

export default RadialChart;
