import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import useClass from "../../../hooks/useClass";
import Filters from "../../../components/Common/Filters";
import { useSelector } from "react-redux";
import campaignSegments from "./Columns"
import Create from "./Create";
import { getSegmentList } from "../../../store/campaign/segment";
const Segments = () => {
  document.title = "Segment | TapFin";
  const { permission, dispatch} = useClass();

  const column = campaignSegments(permission)
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
  const [createSegment, setCreateSegment] = useState(false)
  const {list, loading, totalList} = useSelector((state)=> state.campaignSegment);
  const fetchSegmentList = (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getSegmentList({page: pagination?.page,
      perPage: pagination?.perPage,
      search: searchText,
      dateRange: dateRange}));
  };


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Segments" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0">Segments List</h5>
                  <Filters
                    fetchDataFromServer={fetchSegmentList}
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.LENDERS?.LIST?.CREATE_NEW_LENDER}
                    onCreateButtonClick={() => setCreateSegment(true)}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    data={list}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={column}
                    paginationTotalRows={totalList}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Create isOpen={createSegment} setIsOpen={setCreateSegment} />
      </div>
    </>
  );
};

export default Segments;

