import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import useClass from "../../../hooks/useClass";
import LoadingButton from "../../../components/Common/LoadingButton";
import ConfigCanban from "../../../components/Config-Canban/ConfigCanban";
import { partnerConfigFields } from "../../../constants/constants";
import { validateForm } from "../../../utils/helper";

const Details = () => {

  const { adminServer, toaster, permission } = useClass();
  const { partnerCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({});
  const [form, setForm] = useState({});
  const [prevDetails, setPrevDetails] = useState({});
  const [selectedConfigFields, setSelectedConfigFields] = useState([]);

  useEffect(() => {
    getPartnerDetails(partnerCode);
  }, []);

  const getPartnerDetails = async (partnerCode) => {
    try {
      const response = await adminServer.getPartner(partnerCode);
      const data = response?.data?.data;
      setPartnerDetails(data);
      setPrevDetails(data);
      setForm(response?.data?.data?.config);
    } catch (error) {
      toaster.show(true, error?.message);
    }
  };

  const handlePartnerDetailsChange = (key, value) => {
    if(permission?.PARTNERS?.DETAILS?.UPDATE_PARTNER_DETAILS){
      setPartnerDetails((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      toaster.show(true, 'You dont have permission');
    }
  };

  const checkForDirty = () => {
    return prevDetails?.isActive !== partnerDetails?.isActive;
  }

  const handlePartnerDetailsUpdate = async () => {
    try {
      const result = await toaster.confirmation("Update");
      if (result?.isConfirmed) {
        setIsLoading(true);
        if(checkForDirty()){
          handleBasicDetailsUpdate();
        }
        const valid = validateForm(form);
        if(valid){
          if(partnerDetails?.config){
            handleConfigUpdate();
          } else {
            handleConfigCreation();
          }
        } else {
          throw new Error('Please fill all selected config');
        }
      }
    } catch (error) {
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const handleBasicDetailsUpdate = async () => {
    try {
      const payload = {
        isActive: partnerDetails?.isActive,
      };
      const response = await adminServer.updatePartner(partnerCode, payload);
      setIsLoading(false);
      if (response?.data?.success) {
        toaster.show(false, "Partner Info updated successfully");
        // window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch(error) {
      toaster.show(true, error?.message)
    }
  }

  const handleConfigCreation = async () => {
    try {
      const response = await adminServer.createPartnerConfig({partnerCode, config: form});
      setIsLoading(false);
      if(response?.data?.success){
        toaster.show(false, 'Configuration created successfully');
        // window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch(error) {
      toaster.show(true, error?.message)
    }
  }

  const handleConfigUpdate = async () => {
    try {
      const response = await adminServer.updatePartnerConfig(partnerDetails?.configId, {config: form});
      setIsLoading(false);
      if(response?.data?.success){
        toaster.show(false, 'Configuration updated successfully');
        window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch(error) {
      toaster.show(true, error?.message)
    }
  }

  const handleConfigInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <div>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="partners" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Basic Details</div>
                  <div className="form-check form-switch form-switch-md">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={partnerDetails?.isActive || false}
                      onChange={(e) => handlePartnerDetailsChange("isActive", e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      style={partnerDetails?.isActive ? { color: "green" } : { color: "red" }}
                    >
                      {partnerDetails?.isActive ? "Active" : "In-Active"}
                    </label>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Partner Name</Row>
                      <Row className="data-value me-4">{partnerDetails?.name}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Partner Code</Row>
                      <Row className="data-value me-4">{partnerDetails?.code}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created By</Row>
                      <Row className="data-value me-4">
                        {partnerDetails?.createdBy}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created Date</Row>
                      <Row className="data-value me-4">
                        {new Date(partnerDetails?.createdAt).toLocaleDateString()}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <ConfigCanban
              fields={partnerConfigFields}
              form={form}
              setForm={setForm}
              handleInput={handleConfigInput}
              setSelectedConfigFields={setSelectedConfigFields}
            />
          </Row>
          {permission?.PARTNERS?.DETAILS?.UPDATE_PARTNER_DETAILS && <Row className="mt-4">
            <Col className="d-flex justify-content-center gap-4">
              <LoadingButton
                color={"success"}
                onClick={handlePartnerDetailsUpdate}
                buttonContent={"Update"}
                isLoading={isLoading}
              />
            </Col>
          </Row>}
        </Container>
      </Row>
    </div>
  );
};

export default Details;
