import React, { useEffect, useRef, useState } from 'react';
import { 
  Card, 
  CardBody, 
  CardHeader, 
  Container, 
  Row, 
  Col,
  Button
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useParams } from 'react-router-dom';
import useClass from '../../hooks/useClass';
import { updateLoanAction } from '../../store/loan/loanAction';
import { customerData } from '../../utils/constants';
import Applications from './Applications';
import Entity from './Entity';
import CustomerModal from './Modal';
import { useSelector } from 'react-redux';
import { clearREKYCTaskAdditionalDocs, getApplicationFieldStatus, updateRekycFields, updateTaskFields } from '../../store/application/applicationAction';
import { getCustomerBasicDetails, getEntityDetails } from '../../store/application/customer';
import Accordions from '../../components/Common/Accordions';
import EmiSchedule from './EmiScedule';
import Payment from './payment/Payment';

const Details = (props) => {

  const { server, toaster, permission, dispatch } = useClass();
  const { customerCode } = useParams();
  const { activeApplicationIndex, applicationList } = useSelector((state) => (state?.applicationAction));
  const { basicDetails, entityDetails, applicationDetails } = useSelector((state) => (state?.customer));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getCustomerBasicDetails(customerCode));
    dispatch(getEntityDetails(customerCode));
    return () => {
      dispatch(clearREKYCTaskAdditionalDocs());
    }
  }, []);

  useEffect(() => {
    if(applicationList?.[activeApplicationIndex]?.applicationId){
      dispatch(getApplicationFieldStatus(applicationList?.[activeApplicationIndex]?.applicationId));
    }
  }, [applicationList, activeApplicationIndex]);

  const handleSendToLender = async (applicationId, lenderCode, parentLoanId) => {
    try {
      const result = await toaster.confirmation('Send');
      if(result?.isConfirmed){
        const childLoanCreateResponse = await server.createChildLoan({parentLoanUid: parentLoanId, lenders: [{lenderCode: lenderCode}]});
        if(childLoanCreateResponse?.data?.success){
          const response = await server.sendToLender({lender: lenderCode, applicationId, customerCode});
          if(response?.data?.success){
            toaster.show(false, 'Email Sent');
            window.location.reload();
          } else {
            window.location.reload();
            throw new Error(response?.data?.message);
          }
        } else {
          throw new Error(childLoanCreateResponse?.data?.message);
        }
      }
    } catch(error){
      toaster.show(true, error?.message);
    }
  }

  const handleAction = async (action, loanUid, productCode, applicationId) => {
    dispatch(updateLoanAction({action, loanUid, productCode, applicationId}));
    setIsOpen(true);
  }

  const handleFieldAction = (action, field, otherData=null) => {
    if(action?.code === 'CREATE_TASK'){
      dispatch(updateTaskFields({field, code: action?.code, data: otherData}));
    } else if(action?.code === 'RE_KYC') {
      dispatch(updateRekycFields({field, code: action?.code, data: otherData}));
    } else {
      handleApplicationFieldApproveReject(action, field);
    }
  }

  const handleApplicationFieldApproveReject = async (action, field) => {
    try {
      const result = await toaster.confirmation(action?.label);
      if(result?.isConfirmed){
        const payload = {
          status: action?.code, 
          field:field, 
          applicationId: applicationList?.[activeApplicationIndex].applicationId, 
          customerCode: customerCode
        }
        const response = await server.updateApplicationFieldStatus(payload);
        if(response?.data?.success){
          toaster.show(false, 'Succesfully done');
          window.location.reload();
        } else {
          throw new Error(response?.data?.message);
        }
      }
    } catch(error){
      toaster.show(true, error?.message);
    }
  }

  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="customers" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader className='d-flex justify-content-between'>
                  <div className='section-heading'>Contact Person Details</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {Object.keys(basicDetails || {})?.map((key, index) => {
                      if(customerData[key]){
                        return (
                          <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12' key={`basic-details${index}`}>
                            <div className='data-key ms-4'>{customerData[key]}</div>
                            <div className='data-value me-4'>{key === 'createdBy' && basicDetails?.[key] === 'Admin User' ? 'Customer' :key ==="leadSource" && basicDetails?.[key] === 'Tapfin'?"Website": basicDetails?.[key]}</div>
                          </div>
                        )
                      }
                    })}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {Object.keys(entityDetails || {})?.length > 0 && 
            <Row >
              <Col xl={12}>
                <Entity
                  customerCode={customerCode}
                  entityFinancialData={applicationList?.[activeApplicationIndex]?.additionalData}
                  handleFieldAction={handleFieldAction}
                  entityDetails={entityDetails}
                />
              </Col>
            </Row>}
            <Row>
            <Accordions items={[
              {
                title: 'Applications',
                content: (
                  <Applications
                    customerCode={customerCode}
                    handleAction={handleAction}
                    handleSendToLender={handleSendToLender}
                    handleFieldAction={handleFieldAction}
                  />
                )
              },
              // {
              //   title: 'Emi Schedule',
              //   content: (
              //     <EmiSchedule
              //     customerCode={customerCode}
              //     />
              //   )
              // },
              {
                title: 'Payments',
                content: (
                  <Payment
                   customerCode={customerCode}
                  />
                )
              }
            ]} />
          </Row>
        </Container>
      </Row>
      <CustomerModal 
        customerCode={customerCode}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
      />
    </>
  )
}

export default Details;
