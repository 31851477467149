import React, { useEffect } from "react";
import { Row, Container, Card, CardBody, CardHeader, Col, Badge } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import useClass from "../../../hooks/useClass";
import { useParams } from "react-router-dom";
import { getCampaignTemplateDetail } from "../../../store/campaign/template";
import { useSelector } from "react-redux";

const Details = () => {
  const { toaster, dispatch } = useClass();
  const { templateId } = useParams();

  const { whatsAppTemplate, emailTemplate, smsTemplate } = useSelector(
    (state) => state?.campaignTemplate?.detail
  );
  console.log(whatsAppTemplate, emailTemplate, smsTemplate);
  const getTemplateDetail = async () => {
    dispatch(getCampaignTemplateDetail(templateId));
  };

  useEffect(() => {
    getTemplateDetail();
  }, [dispatch, templateId]);

  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Template" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader>
                  <div className="d-flex gap-4 justify-content-between">
                    <div className="section-heading">Template Details</div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {/* WhatsApp Template Details */}
                    {whatsAppTemplate && (
                      <Col>
                        <h6 className="mb-2">
                          <Badge color="success">WhatsApp Template</Badge>
                        </h6>
                        <Row lg={4}>
                          <p>
                            <strong>Template ID:</strong>{" "}
                            {whatsAppTemplate.whatsapptemplate_id}
                          </p>
                          <p>
                            <strong>Template Name:</strong>{" "}
                            {whatsAppTemplate.templateName}
                          </p>
                          <p>
                            <strong>Language:</strong>{" "}
                            {whatsAppTemplate.language}
                          </p>
                          <p>
                            <strong>Body:</strong> {whatsAppTemplate.body}
                          </p>
                          <p>
                            <strong>Footer:</strong> {whatsAppTemplate.footer}
                          </p>
                          <p>
                            <strong>Header Type:</strong>{" "}
                            {whatsAppTemplate.header_type || "-"}
                          </p>
                          <p>
                            <strong>Header Content:</strong>{" "}
                            {whatsAppTemplate.header_content || "-"}
                          </p>
                          <p>
                            <strong>Button Type:</strong>{" "}
                            {whatsAppTemplate.button_type || "-"}
                          </p>
                          <p>
                            <strong>Button Label:</strong>{" "}
                            {whatsAppTemplate.button_label || "-"}
                          </p>
                          <p>
                            <strong>Button Value:</strong>{" "}
                            {whatsAppTemplate.button_value || "-"}
                          </p>
                          <p>
                            <strong>Template Status:</strong>{" "}
                            {whatsAppTemplate.template_status}
                          </p>
                          <p>
                            <strong>Button Label:</strong>{" "}
                            {whatsAppTemplate.button_label || "-"}
                          </p>
                          <p>
                            <strong>Created At:</strong>{" "}
                            {new Date(
                              whatsAppTemplate.template_created_at
                            ).toLocaleString()}
                          </p>
                          <p>
                            <strong>Updated At:</strong>{" "}
                            {new Date(
                              whatsAppTemplate.template_updated_at
                            ).toLocaleString()}
                          </p>
                        </Row>
                      </Col>
                    )}

                    {/* Email Template Details */}
                    {emailTemplate && (
                      <Col>
                      <h6 className="mb-2">
                          <Badge color="success">Email Template</Badge>
                        </h6>
                      <Row lg={4}>
                        <p>
                          <strong>Template ID:</strong>{" "}
                          {emailTemplate.email_template_id}
                        </p>
                        <p>
                          <strong>Template Name:</strong>{" "}
                          {emailTemplate.email_template_name}
                        </p>
                        <p>
                          <strong>Email Subject:</strong>{" "}
                          {emailTemplate.emailSubject}
                        </p>
                        <p>
                          <strong>Email Body:</strong> {emailTemplate.emailBody}
                        </p>
                        <p>
                          <strong>Description:</strong>{" "}
                          {emailTemplate.template_description}
                        </p>
                        <p>
                          <strong>Status:</strong>{" "}
                          {emailTemplate.template_status}
                        </p>
                        <p>
                          <strong>Created At:</strong>{" "}
                          {new Date(
                            emailTemplate.template_created_at
                          ).toLocaleString()}
                        </p>
                        <p>
                          <strong>Updated At:</strong>{" "}
                          {new Date(
                            emailTemplate.template_updated_at
                          ).toLocaleString()}
                        </p>
                      </Row>
                      </Col>
                    )}

                    {/* SMS Template Details */}
                    {smsTemplate && (
                      <Col>
                       <h6 className="mb-2">
                          <Badge color="success">SMS Template</Badge>
                        </h6>
                      <Row lg={4}>
                        <p>
                          <strong>Template ID:</strong>{" "}
                          {smsTemplate.sms_template_id}
                        </p>
                        <p>
                          <strong>SMS Text:</strong> {smsTemplate.smsText}
                        </p>
                        <p>
                          <strong>DLT ID:</strong> {smsTemplate.dltId}
                        </p>
                        <p>
                          <strong>Sender ID:</strong> {smsTemplate.senderId}
                        </p>
                        <p>
                          <strong>Description:</strong>{" "}
                          {smsTemplate.template_description}
                        </p>
                        <p>
                          <strong>Status:</strong> {smsTemplate.template_status}
                        </p>
                        <p>
                          <strong>Created At:</strong>{" "}
                          {new Date(
                            smsTemplate.template_created_at
                          ).toLocaleString()}
                        </p>
                        <p>
                          <strong>Updated At:</strong>{" "}
                          {new Date(
                            smsTemplate.template_updated_at
                          ).toLocaleString()}
                        </p>
                      </Row>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
};

export default Details;
