import { axiosInstance, multipartHeader } from "./base";
import { headers } from "./base";

export class CampaignService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;
    
     getTemplates = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-templates?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getTempalteDetail= (templateId) => {
        console.log("templateId", templateId)
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-template/${templateId}`, {headers: headers()});
    }

    createCampaignEmailTemplate = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-email-template`,payload, {headers: multipartHeader()} )
    }

    createCampaignWhatsAppTemplate = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-whatsapp-template`,payload, {headers: multipartHeader()} )
    }

    createCampaignSMSTemplate = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-template`,payload, {headers: headers()} )
    }

    getSegmentTableColumns = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/segment/table-column`, {headers: headers()})
    }

    getSegmentQueryData = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/segment/view`,payload, {headers: headers()})
    }

    createSegment = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/segment`,payload, {headers: headers()})
    }

    getSegments = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/segment?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getSegmentDetail = (segmentId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/segment/${segmentId}`, {headers: headers()});
    }

    createCampaignFlow = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/campaign`,payload, {headers: headers()} )
    }

    getCampaignFlowList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/campaign?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getCampaignFlowDetail = (campaignFlowId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/campaign/${campaignFlowId}`, {headers: headers()});
    }


}

