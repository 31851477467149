import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "../Common/withRouter";
import profileIcon from "../../assets/images/brands/mail_chimp.png";
import { useSelector } from "react-redux";
import useClass from "../../hooks/useClass";

const ProfileMenu = () => {

  const { navigate } = useClass();
  const userDetails = useSelector((state) => state?.userDetails?.details);
  const [profileMenu, setProfileMenu] = useState(false);

  const handleLogout = () => {
    navigate('/login')
    localStorage.removeItem("token")
    window.location.reload();

  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Dropdown
          isOpen={profileMenu}
          toggle={() => setProfileMenu(!profileMenu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item d-flex align-items-center"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={profileIcon}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ms-2 me-2">
              {userDetails?.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag={Link} to="/userprofile">
              <i className="ri-user-line align-middle me-2" />
              Profile
            </DropdownItem>
            <div className="dropdown-divider" />
            <Link onClick={handleLogout} className="dropdown-item">
              <i className="ri-shut-down-line align-middle me-2 text-danger" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default withRouter(ProfileMenu);
