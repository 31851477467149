import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody
} from 'reactstrap';
import FieldModel from "./FieldModel";
import useClass from "../../../hooks/useClass";
import Model from "./Model";
import ActionDropdown from "../../../components/Dropdown/ActionDropdown";
const Fields = ({ productCode }) => {
  const { adminServer, toaster, permission } = useClass();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenStep, setIsOpenStep] = useState(false);
  const [stepIndex, setStepIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [stepsAndFields, setStepsAndFields] = useState([]);
  const [searchProductField, setSearchProductField] = useState("");
  const [searchStepField, setSearchStepField] = useState("");
  const [savedFields, setSavedField] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const createNewField = (stepIndex) => {
    setStepIndex(stepIndex);
    setIsOpen(true);
  };

  const createNewStep = () => {
    setIsOpenStep(true);
  };

  const handleToggle = (index) => {
    if (openIndex === index) {
      setOpenIndex();
    } else {
      setOpenIndex(index);
    }
  };

  const handleGetMappedProductFields = async () => {
    try {
      setLoading(true);
      const response = await adminServer.getMappedProductFields(productCode);
      const { data } = response?.data;
      setSavedField(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const handleAddNewFieldData = async (fieldForm) => {
    try {
      fieldForm = { ...fieldForm, stepCode: stepsAndFields[stepIndex]?.slug };
      setLoading(true);
      const response = await adminServer.addNewFieldToStep(fieldForm);
      setLoading(false);
      setStepsAndFields((prev) => {
        if (prev[stepIndex].JourneyFields) {
          prev[stepIndex].JourneyFields = [
            ...prev[stepIndex].JourneyFields,
            response?.data?.data,
          ];
        } else {
          prev[stepIndex] = {
            ...prev[stepIndex],
            JourneyFields: [],
          };
          prev[stepIndex].JourneyFields = [
            ...prev[stepIndex].JourneyFields,
            response?.data?.data,
          ];
        }
        return prev;
      });
    } catch (error) {
      setLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const getStepsAndFields = async () => {
    try {
      setLoading(true);
      const response = await adminServer.getStepsAndFields();
      const { data } = response?.data;
      let tempStepsAndFields = [...data];
      tempStepsAndFields?.forEach((currStep, stepIndex) => {
        currStep?.JourneyFields?.forEach((currField, fieldIndex) => {
          tempStepsAndFields[stepIndex].JourneyFields[fieldIndex] = {
            ...tempStepsAndFields[stepIndex].JourneyFields[fieldIndex],
            added: false,
          };
        });
      });
      setStepsAndFields(tempStepsAndFields);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const handleAddFieldToProduct = async (stepIndex, fieldIndex) => {
    try {
      setLoading(true);
      let tempStepsAndFields = [...stepsAndFields];
      if (
        tempStepsAndFields[stepIndex].JourneyFields[fieldIndex].added &&
        tempStepsAndFields[stepIndex].JourneyFields[fieldIndex].rowId
      ) {
        const result = await toaster?.confirmation(
          "Yes",
          "Are you sure you want to remove this field from the mapping?"
        );
        if (!result?.isConfirmed) return;

        const payload = {
          journeyFieldsId:
            tempStepsAndFields[stepIndex].JourneyFields[fieldIndex].id,
          productCode: productCode,
          journeyStepsId:
            tempStepsAndFields[stepIndex].JourneyFields[fieldIndex]
              .journeyStepsId,
          id: tempStepsAndFields[stepIndex].JourneyFields[fieldIndex].rowId,
        };
        const response = await adminServer.deleteFieldFromProduct(payload);
        if (!response?.data?.success) {
          throw new Error(response?.data?.message);
        }
        toaster.show(false, "The field has been successfully unmapped.");
      }
      setLoading(false);
      tempStepsAndFields[stepIndex].JourneyFields[fieldIndex].added =
        !tempStepsAndFields[stepIndex].JourneyFields[fieldIndex].added;
      setStepsAndFields(tempStepsAndFields);
      setLoading(false);
    } catch (error) {
      toaster.show(true, error?.message);
      setLoading(false);
    }
  };

  const addAllFieldsToProduct = () => {
    let tempStepsAndFields = [...stepsAndFields];

    tempStepsAndFields.forEach((currStep, stepIndex) => {
      currStep?.JourneyFields?.forEach((currField, fieldIndex) => {
        tempStepsAndFields[stepIndex].JourneyFields[fieldIndex] = {
          ...tempStepsAndFields[stepIndex].JourneyFields[fieldIndex],
          added: true,
        };
      });
    });
    setStepsAndFields(tempStepsAndFields);
  };

  const removeAllFieldsToProduct = () => {
    let tempStepsAndFields = [...stepsAndFields];

    tempStepsAndFields.forEach((currStep, stepIndex) => {
      currStep?.JourneyFields?.forEach((currField, fieldIndex) => {
        tempStepsAndFields[stepIndex].JourneyFields[fieldIndex] = {
          ...tempStepsAndFields[stepIndex].JourneyFields[fieldIndex],
          added: false,
        };
      });
    });
    setStepsAndFields(tempStepsAndFields);
  };

  const handleSubmitStep = async () => {
    try {
      let FinalSteps = [];
      stepsAndFields.forEach((currStep) => {
        currStep?.JourneyFields?.forEach((currField) => {
          if (currField.added && !currField.rowId) {
            let finalStep = { ...currField };
            delete finalStep.added;
            FinalSteps.push(finalStep);
          }
        });
      });

      const payload = {
        productCode,
        fields: FinalSteps,
      };
      if (payload.fields.length == 0) {
        toaster.show(true, "Kindly add the new fields.");
        return;
      }

      setLoading(true);

      const result = await toaster.confirmation(
        "Yes",
        "Are you sure you want to save these fields?"
      );
      if (result?.isConfirmed) {
        const response = await adminServer.mapProductWithFields(payload);
        if (!response?.data?.success) {
          throw new Error(response?.data?.message);
        }
        handleGetMappedProductFields()
        toaster.show(false, "fields has been mapped successfully");
      }
      setLoading(false);
    } catch (error) {
      toaster.show(true, error?.message);
      setLoading(false);
    }
  };

  // add new field to step
  const handleAddNewStep = async (stepForm) => {
    try {
      setLoading(true);
      const response = await adminServer.createNewFieldStep(stepForm);
      if (!response?.data?.success) {
        throw new Error(response?.data?.message);
      }
      setLoading(false);
      setStepsAndFields((prev) => [...prev, response?.data?.data]);
    } catch (error) {
      setLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const handleMandatoryField = async(e,field) => {
     try {
    e.stopPropagation();
     setLoading(true);
     const productField = field?.added
     const payload = {productCode,productField }
     const result  = await toaster?.confirmation("Yes","Are you sure you want to update the mandatory status?")
      if(result?.isConfirmed){
     const response  = await adminServer.changeMandatoryStatus(payload);
     handleGetMappedProductFields()
     if (!response?.data?.success) {
      throw new Error(response?.data?.message);
    }
   }
    setLoading(false);
   } catch (error) {
    setLoading(false);
     toaster.show(true, error?.message);
   }
  }

  const getSelectedFieldCount = () => {
    let fieldCount = 0;
    {
      stepsAndFields.map((step, index) =>
        step?.JourneyFields?.map((field, id) => {
          fieldCount += field.added ? 1 : 0;
        })
      );
    }
    return fieldCount;
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetMappedProductFields();
      await getStepsAndFields();
      setIsDataLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isDataLoaded && stepsAndFields.length && savedFields.length) {
      let tempStepsAndFields = [...stepsAndFields];

      tempStepsAndFields.forEach((currStep, stepIndex) => {
        currStep?.JourneyFields?.forEach((currField, fieldIndex) => {
          let isSelected = savedFields.find(
            (savedField) =>
              savedField?.journeyFieldsId === currField.id &&
              savedField?.journeyStepsId === currField.journeyStepsId
          );
          tempStepsAndFields[stepIndex].JourneyFields[fieldIndex] = {
            ...tempStepsAndFields[stepIndex].JourneyFields[fieldIndex],
            added: isSelected,
            rowId: isSelected?.id,
          };
        });
      });
      setStepsAndFields(tempStepsAndFields);
    }
  }, [savedFields, isDataLoaded]);

  return (
<div className="d-flex flex-wrap justify-content-center gap-3">
      <Card
        className="border-light rounded-3 shadow-sm"
        style={{ height: '30rem', width:"28%"}}
      >
        <CardHeader className="bg-light border-bottom d-flex align-items-center justify-content-between">
          <h5 className="mb-0" style={{ fontSize: '18px' }}>
            Map Product With Fields({getSelectedFieldCount()})
          </h5>
          {getSelectedFieldCount() > 0 && (
            <Button
              onClick={() => handleSubmitStep()}
              outline
              color="primary"
            >
              Save
            </Button>
          )}
        </CardHeader>
        <CardBody className="d-flex flex-column">
          <Input
            onChange={(e) => setSearchProductField(e.target.value)}
            className="mb-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div className="overflow-auto flex-grow-1" 
          style={{ height: '20rem'}}>
            {stepsAndFields.map((step, index) => (
              <div className="mb-2" key={index}>
                {step?.JourneyFields?.map((field, id) =>
                  field.added &&
                  field.name
                    .toLowerCase()
                    .includes(searchProductField.toLowerCase()) ? (
                    <div
                      className="d-flex align-items-center justify-content-between p-2 rounded mb-2 bg-white cursor-pointer shadow-sm"
                      key={id}
                    >
                      {field?.name}
                      <div>
                      {field?.added !==true && <Button
                        color={field?.added?.isMendatory ? 'success' : 'danger'}
                        size="sm"
                        onClick={(e) => handleMandatoryField(e, field)}
                        className="ms-2"
                      >
                        {field?.added?.isMendatory ? 'Mandatory' : 'Non-Mandatory'}
                      </Button>}
                    <ActionDropdown 
                     color='red'
                     className='ri-more-fill' 
                     options={[{ label: 'delete',
                      code: 'VERIFIED',
                      color: 'red',
                      className: 'ri-delete-bin-fill'}]}              
                     onClick={()=>handleAddFieldToProduct(index, id)}
                    />
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </div>
        </CardBody>
      </Card>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <Button
          onClick={() => addAllFieldsToProduct()}
          outline
          color="primary"
        >
          Add All Fields To Left
        </Button>
      </div>

      <Card
        className="border-light rounded-3 shadow-sm"
        style={{ height: '30rem', width:"25%"}}
      >
        <CardHeader className="bg-light border-bottom d-flex align-items-center justify-content-between">
          <h5 className="mb-0" style={{ fontSize: '18px' }}>
            Journey Steps({stepsAndFields ? stepsAndFields.length : 0})
          </h5>
          <Button
            outline
            color="primary"
            onClick={createNewStep}
          >
            Add New Step +
          </Button>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          <Input
            onChange={(e) => setSearchStepField(e.target.value)}
            className="mb-3"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div className="overflow-auto flex-grow-1" 
          style={{ height: '20rem'}}>
            {stepsAndFields.map((step, index) => {
              let showStep = false;
              let fieldVisCount = 0;
              step?.JourneyFields?.forEach((curField, fieldIndex) => {
                if (!curField.added) fieldVisCount++;
                if (!showStep)
                  showStep = curField.name.toLowerCase().includes(searchStepField.toLowerCase());
                });
                console.log(showStep, step?.slug, fieldVisCount);
                return (showStep || searchStepField?.length === 0) ? (
                <div className="mb-2" key={index}>
                  <Accordion open={openIndex} toggle={handleToggle}>
                    <AccordionItem>
                      <AccordionHeader targetId={index}>
                        {step?.slug}
                      </AccordionHeader>
                      <AccordionBody accordionId={index}>
                        <Button
                          className="w-100 mb-2"
                          color="primary"
                          outline
                          onClick={() => createNewField(index)}
                        >
                          Add New Field +
                        </Button>
                        {step?.JourneyFields?.map((field, id) =>
                          !field.added &&
                          field.name
                            .toLowerCase()
                            .includes(searchStepField.toLowerCase()) ? (
                            <div
                              onClick={() => handleAddFieldToProduct(index, id)}
                              className="p-2 rounded mb-2 bg-white cursor-pointer shadow-sm"
                              key={id}
                            >
                              {field?.name}
                            </div>
                          ) : null
                        )}
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </div>
              ) : null;
            })}
          </div>
        </CardBody>
      </Card>
      <FieldModel
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSubmit={handleAddNewFieldData}
      />
      <Model
        isOpen={isOpenStep}
        setIsOpen={setIsOpenStep}
        handleSubmit={handleAddNewStep}
      />
    </div>
  );
 };

export default Fields;
