import { axiosInstance } from "./base";
import { headers } from "./base";

export class AdminService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;

    //Roles api
    getRoleList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/roles?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    createNewRole = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-role`, payload, {headers: headers()});
    }

    fetchCurrentRoleData = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-role/${id}`, {headers: headers()});
    }

    fetchPermissionMasterData = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/permissions`, {headers: headers()});
    }

    updateRoleDetails = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/update-role`, payload, {headers: headers()});
    }

    updateRolePermissions = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/update-permission`, payload, {headers: headers()});
    }


    //Users api
    getUserList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-users?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    createNewUser = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-user`, payload, {headers: headers()});
    }

    fetchCurrentUserDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/get-user/${id}`, {headers: headers()});
    }

    updateUserDetails = (id, payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/update-user/${id}`, payload, {headers: headers()});
    }


    //Products api
    getProducts = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/products?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`,{headers: headers()});
    }

    getProduct = (productCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/product/${productCode}`, {headers: headers()});
    }

    createProduct = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/product`, payload, {headers: headers()});
    }

    getProductCategory = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/product-category`, {headers: headers()});
    }

    updateProductConfig = (productCode, payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/product/${productCode}`, payload, {headers: headers()});
    }


    // Product Scheme api
    getProductSchemeList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/product-scheme?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getSchemeDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/product-scheme/${id}`, {headers: headers()});
    }

    createScheme = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/product-scheme`, payload, {headers: headers()});
    }

    updateScheme = (id, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/product-scheme/${id}`, payload, {headers: headers()});
    }


    // Portal User api
    getPortalUsers = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/cos-users?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`,{headers: headers()});
    }

    getPortalUser = (userId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/cos-user/${userId}`,{headers: headers()});
    }


    // Lender api
    createLender = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/lender`,payload,{headers: headers()});
    }

    getLenders = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lenders?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`,{headers: headers()});
    }

    getLender = (lenderCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lender/${lenderCode}`,{headers: headers()});
    }

    getLenderExtraDetails = (lenderCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/lender/details/${lenderCode}`,{headers: headers()});
    }

    addLenderNewAddress = (lenderCode, payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/lender/details/${lenderCode}`,payload,{headers: headers()});
    }

    getLenderAddress = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/lender/gstDetail`,payload,{headers: headers()})
    }

    updateLender = (lenderCode, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/lender/${lenderCode}`,payload ,{headers: headers()});
    }

    createLenderConfig = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/lender/config`, payload, {headers: headers()});
    }

    updateLenderConfig = (lenderId, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/lender/config/${lenderId}`, payload, {headers: headers()});
    }


    // Partner api
    getPartners = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/partners?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`,{headers: headers()});
    }

    getPartner = (partnerCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/partner/${partnerCode}`,{headers: headers()});
    }
    
    createPartner = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/partner`,payload,{headers: headers()});
    }

    updatePartner = (partnerCode, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/partner/${partnerCode}`, payload, {headers: headers()});
    }

    createPartnerConfig = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/partner/config`, payload, {headers: headers()});
    }

    updatePartnerConfig = (partnerId, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/partner/config/${partnerId}`, payload, {headers: headers()});
    }
  

    // Teams api
    createTeam = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/team`, payload, {headers: headers()});
    }

    getTeamsList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/team?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getTeamDetails = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/team/${id}`, {headers: headers()});
    }

    addMemberInTeam = (id, payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/team/${id}/member`, payload, {headers: headers()});
    }

    updateTeamDetails = (id, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/team/${id}`, payload, {headers: headers()});
    }

    updateMemberActivation = (teamId, payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/team/${teamId}/member`, payload, {headers: headers()});
    }


    // Lead assign api
    assignLead = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/assign-lead`, payload, {headers: headers()});
    }

    assignApplication = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/assign-application`, payload, {headers: headers()});
    }


    // Agreement api

    getAgreementList = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/agreement?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getAgreementDetails = (code) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/agreement/${code}`, {headers: headers()});
    }

    createNewAgreement = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/agreement`, payload, {headers: headers()});
    }

    updateAgreement = (payload, code) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/agreement/${code}`, payload, {headers: headers()});
    }

    approveAgreement = (code) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/agreement/${code}/approve`, {}, {headers: headers()});
    }


    //api logs
    getAllLogs = (page=1, perPage=20, search='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/log?page=${page}&perPage=${perPage}&search=${search}`,{headers: headers()});
    }

    getLog = (logId) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/log/${logId}`, {headers: headers()});
    }

    //api step and field config
    getStepsAndFields = () => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/fieldstep`, {headers: headers()});
    }

    addNewFieldToStep = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-field`,payload, {headers: headers()});
    }

    createNewFieldStep = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/create-fieldstep`,payload, {headers: headers()});
    }

    mapProductWithFields = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/map-productfield`,payload, {headers: headers()});
    }

    getMappedProductFields = (productCode) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/productfields/${productCode}`, {headers: headers()});
    }

    deleteFieldFromProduct = (payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/updateproductfield`,payload, {headers: headers()});
    }

    changeMandatoryStatus = (payload) => {
        return axiosInstance.patch(`${this.CMS_BASE_URL}/productfield/mendatory-status`,payload, {headers: headers()}); 
    }

}
