import React, { useState, useEffect } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';

const CreateVehicle = (props) => {
  const { toaster, oemServer, permission, navigate } = useClass();
  const { isOpen, setIsOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [manufactureOptions, setManufactureOptions] = useState([]);
  const [form, setForm] = useState({
    assetOemCode: '',
    modelName: '',
    variantName: '',
    modelYear: ''
  });

  useEffect(() => {
    const fetchManufactures = async () => {
      try {
        const response = await oemServer.getManufacture(); 
        if (response?.data?.success) {
          const options = response.data.data.map(oem => ({
            label: oem.name,
            value: oem.code
          }));
          setManufactureOptions(options);
        } else {
          throw new Error(response?.data?.message);
        }
      } catch (error) {
        toaster.show(true, error?.message || 'Failed to fetch manufacturers');
      }
    };

    fetchManufactures();
  }, []);

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleVehicleCreation = async () => {
    try {
      setIsLoading(true);
      if (validateForm()) {
        const payload = {
          ...form,
          assetOemCode: form?.assetOemCode?.value, // Ensure it's a string
        };
        const response = await oemServer.createVehicle(payload); 
        setIsLoading(false);
        if (response?.data?.success) {
          handleModelClose();
          navigate(`/vehicle/details/${response?.data?.data?.code}`);
          toaster.show(false, 'Vehicle created successfully');
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please fill all required fields');
      }
    } catch (error) {
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });

    return result;
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Create Vehicle</h5>
          <button type="button" onClick={handleModelClose} className="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mt-4">
            <Dropdown
              label="Manufacturer"
              required={true}
              name="assetOemCode"
              value={form.assetOemCode}
              handleInput={handleInput}
              options={manufactureOptions}
              placeholder="Select Manufacturer"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Model Name"
              required={true}
              name="modelName"
              value={form.modelName}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Variant Name"
              required={true}
              name="variantName"
              value={form.variantName}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Model Year"
              required={true}
              name="modelYear"
              value={form.modelYear}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Category"
              name="category"
              value={form.category}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Range"
              name="range"
              value={form.range}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Battery Capacity"
              name="batteryCapacity"
              value={form.batteryCapacity}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Energy Consumption"
              name="energyConsuption"
              value={form.energyConsuption}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Max Speed"
              name="maxSpeed"
              value={form.maxSpeed}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Warranty"
              name="warranty"
              value={form.warranty}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="CO2 Emission Per Km"
              name="co2EmissionPerKm"
              value={form.co2EmissionPerKm}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Cost Per Km"
              name="costPerKm"
              value={form.costPerKm}
              handleInput={handleInput}
              type="text"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Min Price"
              name="minPrice"
              value={form.minPrice}
              handleInput={handleInput}
              type="number"
            />
          </div>

          <div className="mt-4">
            <TextInput
              label="Max Price"
              name="maxPrice"
              value={form.maxPrice}
              handleInput={handleInput}
              type="number"
            />
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" onClick={handleModelClose} className="btn btn-secondary">
            Close
          </button>
          <LoadingButton
            buttonContent="Create"
            isLoading={isLoading}
            onClick={handleVehicleCreation}
            color="success"
          />
        </div>
      </Modal>
    </>
  );
};

export default CreateVehicle;
