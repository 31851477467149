// yourSaga.js
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { Toaster } from '../../components/toaster/Toaster';
import { ApiService } from '../../service/ApiService';
import { getDashboardCampaignData, getDashboardCampaignDataSuccess,
    getDashboardData, getDashboardDataError, getDashboardDataSuccess,
    getDashboardMonthData, getDashboardMonthDataSuccess, getDashboardTeamData,
    getDashboardTeamDataSuccess,getFunnelData, getFunnelDataSuccess, getFunnelDataError } from './dashboard';
const server = new ApiService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchDashboardData() {
    yield takeEvery(getDashboardData.type, getDashboardDataDetails);
}

function* watchFunnelData() {
    yield takeEvery(getFunnelData.type, getFunnelDataWorker);
}

function* watchDashboardMonthData() {
    yield takeEvery(getDashboardMonthData.type, getDashboardMonthDataDetails);
}

function* watchDashboardCampaignData() {
    yield takeEvery(getDashboardCampaignData.type, getDashboardCampaignDetails);
}

function* watchDashboardTeamData() {
    yield takeEvery(getDashboardTeamData.type, getDashboardTeamDetails);
}



const getDashboardDataServer = async () => (
    await server.getDashboardData().then((response) => (response)).catch((error) => (error))
)

const getFunnelDataServer = async (payload) => (
    await server.getFunnelData(payload).then((response) => (response)).catch((error) => (error))
)

const getDashboardMonthDataServer  = async () => (
    await server.getDashboardMonthData().then((response) => (response)).catch((error) => (error))
)

const getDashboardCampaignDataServer = async (payload) => (
    await server.getDashboardCampaignData(payload?.month,String(payload?.year)).then((response) => (response)).catch((error) => (error))
)

const getDashboardTeamsDataServer = async (payload) => (
    await server.getDashboardTeamData(payload?.month,String(payload?.year)).then((response) => (response)).catch((error) => (error))
)


// campaign count
function* getDashboardCampaignDetails(action) {
    try {
        const response = yield call(getDashboardCampaignDataServer,action?.payload);
        if(response?.data?.success){
            const {data} = response?.data;
            
             yield put(getDashboardCampaignDataSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getDashboardDataError(error.message));
        toaster.show(true, error?.message);
    }
}

// team count
function* getDashboardTeamDetails(action) {
    try {
        const response = yield call(getDashboardTeamsDataServer,action?.payload);
        if(response?.data?.success){
            const {data} = response?.data;
            
             yield put(getDashboardTeamDataSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getDashboardDataError(error.message));
        toaster.show(true, error?.message);
    }
}

// month data count 
function* getDashboardMonthDataDetails() {
    try {
        const response = yield call(getDashboardMonthDataServer);
        if(response?.data?.success){
            const {data} = response?.data;
             yield put(getDashboardMonthDataSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getDashboardDataError(error.message));
        toaster.show(true, error?.message);
    }
}



// Worker saga: will be fired on dashboardData actions
function* getDashboardDataDetails() {
    try {
        const response = yield call(getDashboardDataServer);
        if(response?.data?.success){
            const {data} = response?.data;
             yield put(getDashboardDataSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getDashboardDataError(error.message));
        toaster.show(true, error?.message);
    }
}

function* getFunnelDataWorker(action) {
    try {
        console.log("Request Payload>>>", action.payload);
        const response = yield call(getFunnelDataServer, action.payload);
        console.log("API Response>>> ", response);
        if (response?.data?.success) {
            const { data } = response?.data;
            console.log("Funnel Data>>> ", data);
            yield put(getFunnelDataSuccess(data));  // Dispatch success action
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        console.log("Error Fetching Funnel Data>> ", error.message);
        yield put(getFunnelDataError(error.message));  // Dispatch error action
        toaster.show(true, error.message);
    }
}



export default function* DashboardSaga(){
    yield all([
        fork(watchDashboardData),
        fork(watchDashboardMonthData),
        fork(watchDashboardCampaignData),
        fork(watchDashboardTeamData),
        fork(watchFunnelData), 
    ])
};
