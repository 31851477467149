import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/text-input/TextInput";
import Dropdown from "../../../components/Dropdown/Dropdown";
import LoadingButton from "../../../components/Common/LoadingButton";

const CreateProduct = (props) => {

  const { adminServer, toaster } = useClass();
  const { isOpen, setIsOpen } = props;
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryCode, setCategoryCode] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleProductCreation = async () => {
    if (validateForm()) {
      await createProduct({
        name: productName,
        type: productType?.value,
        categoryCode: categoryCode?.value,
        subCategory: subCategory?.value,
      });
    } else {
      toaster.show(true, "Please Fill All Details to create product.");
    }
  };

  const createProduct = async (payload) => {
    try {
      setLoading(true);
      const response = await adminServer.createProduct(payload);
      if (response?.data?.success) {
        setLoading(false);
        setIsOpen(false);
        window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const getAllCategories = async () => {
    try {
      setCategoryLoading(true);
      const response = await adminServer.getProductCategory();
      if (response?.data?.success) {
        setCategoryLoading(false);
        setCategories(response?.data?.data);
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      setCategoryLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const validateForm = () => {
    return (
      productName.trim() !== "" &&
      productType !== "" &&
      categoryCode !== "" &&
      subCategory !== ""
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Product
          </h5>
          <button
            type="button"
            onClick={handleModelClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <TextInput
              name={'productName'}
              label={'Product Name'}
              value={productName}
              required={true}
              handleInput={(key, value) => setProductName(value)}
            />
          </div>
          <div className="mt-4">
            <Dropdown
              label={"Select Category Code"}
              value={categoryCode}
              required={true}
              name={"productCategory"}
              options={categories?.map((category) => ({label: category.name, value: category?.code}))}
              handleInput={(key, value) => setCategoryCode(value)}
            />
          </div>
          <div className="mt-4">
            <Dropdown
              label={"Select Sub-Category Code"}
              value={subCategory}
              required={true}
              name={"subCategory"}
              options={categories.map((category) => ({label: category.name, value: category?.code}))}
              handleInput={(key, value) => setSubCategory(value)}
            />
          </div>
          <div className="mt-4">
            <Dropdown 
              label={'Product Type'}
              value={productType}
              required={true}
              name={'productType'}
              options={[
                {label: 'Loan', value: 'LOAN'}, 
                {label: 'Insurance', value: 'INSURANCE'}, 
                {label: 'Lease', value: 'LEASE'}, 
                {label: 'Software', value: 'SOFTWARE'},
                {label: 'Demand Aggregation', value: 'DEMAND_AGGREGATE'}
              ]}
              handleInput={(key, value) => setProductType(value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleModelClose}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <LoadingButton
            buttonContent={"Create"}
            isLoading={loading}
            onClick={handleProductCreation}
            color={"success"}
          />
        </div>
      </Modal>
    </>
  );
};

export default CreateProduct;
