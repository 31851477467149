import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { statusColors, taskStatus } from '../../constants/constants';
import useClass from '../../hooks/useClass';
import { updateTask } from '../../store/user/userSlice';

const TaskCanban = ({ data }) => {
    const [tasks, setTasks] = useState({});

    const{dispatch} = useClass()
    // Initialize tasks when data prop changes
    useEffect(() => {
        if (data && data.length > 0) {
            const groupedTasks = data.reduce((acc, task) => {
                acc[task.status] = acc[task.status] || [];
                acc[task.status].push(task);
                return acc;
            }, {});
            setTasks(groupedTasks);
        }
    }, [data]);

    
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragStart = (event, task) => {
        event.dataTransfer.setData('task', JSON.stringify(task));
    };

    const handleDragDrop = (event, status) => {
        event.preventDefault();
        const droppedTask = JSON.parse(event.dataTransfer.getData('task'));

        // Update the status of the dropped task
        setTasks(prevTasks => {
            const updatedTasks = { ...prevTasks };
            // Remove task from the old status
            updatedTasks[droppedTask.status] = updatedTasks[droppedTask.status].filter(t => t.name !== droppedTask.name);
            // Add task to the new status
            updatedTasks[status.value] = updatedTasks[status.value] || [];
            updatedTasks[status.value].push({ ...droppedTask, status: status.value });

            return updatedTasks;
        });
          const taskId = droppedTask?.id
        const payload = {taskStatusData: {status: status?.value}}
         dispatch(updateTask({payload,taskId}))
    };

    return (
        <Container>
            <Row>
                {taskStatus.map((status) => (
                    <Col key={status.value} md={4}>
                        <Card 
                            color={statusColors[status?.value]}
                            outline
                            className="border" 
                            onDrop={(e) => handleDragDrop(e, status)} 
                            onDragOver={handleDragOver}
                        >
                            <CardHeader>
                                <h5>{status.label}</h5>
                            </CardHeader>
                            <CardBody className='d-flex row justify-content-center'>
                            {tasks[status.value]?.map((task) => (
                               
                                    <Button
                                       key={task.name}
                                       className="border text-center m-2"
                                       color={statusColors[task?.status]}
                                       outline
                                       draggable 
                                        onDragStart={(e) => handleDragStart(e, task)}
                                    >
                                        {task.name}
                                    </Button>                           
                            ))}
                              </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default TaskCanban;

