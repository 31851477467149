import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import portalUserColumns from "./PortalTableColumns";
import useClass from "../../../hooks/useClass";
import Filters from "../../../components/Common/Filters";
import { getPortalUserList } from "../../../store/admin/adminData";
import { useSelector } from "react-redux";

const PortalUsers = () => {
  document.title = "Portal Users | TapFin";
  const { permission, dispatch } = useClass();
  const columns = portalUserColumns(permission);
  const { list, totalList, loading } = useSelector((state) => (state?.portalUser));
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

  const getPortalUser = (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getPortalUserList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange}));
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Portal Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0"></h5>
                  <div className="d-flex gap-4">
                    <Filters
                      fetchDataFromServer={getPortalUser}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  </div>
                </CardHeader>
                <CardBody>
                  <Table
                    data={list}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={columns}
                    paginationTotalRows={totalList}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PortalUsers;
