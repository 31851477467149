import JoditEditor from 'jodit-react';
import React, {useEffect, useMemo, useState} from 'react';
import Draggable from 'react-draggable';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import useClass from '../../hooks/useClass';
import { getCamReport, updateCamReport } from '../../store/application/customer';
import { useSelector } from 'react-redux';
import { formatDate, formatDate2 } from '../../utils/helper';

const Board = ({header, onCancel, onSave, code}) => {

    const {dispatch} = useClass();
    const {data} = useSelector((state) => (state?.camReport))
    const [content, setContent] = useState('');
    const [openPastData, setOpenPastData] = useState(false);

    useEffect(() => {
        dispatch(getCamReport(code));
    }, [])

    const editorConfig = useMemo(() => ({
        placeholder: '',
        // theme: 'dark',
        width: '100%',
        height: '100%',
        toolbar: false,
        resize: false
    }), []);

    const handleSubmit = () => {
         // Extract plain text from the HTML
        const tempElement = document.createElement("div");
        tempElement.innerHTML = content; // Set the HTML as the content
        const extractedText = tempElement.innerText
        dispatch(updateCamReport({code: code, message: extractedText}));
        onSave();
    }


    return (
        <>
            <Draggable
                handle=".dragger"
            >
                <div style={{
                    position: 'fixed', 
                    top: '50%', 
                    left: '20%', 
                    zIndex: 1001,
                    width: '40vw',
                    minHeight: '40vh',
                    }}
                >
                    <Card className='h-100' style={{border: '1px solid green'}}>
                        <CardHeader className='dragger' style={{backgroundColor: 'lightcoral'}}>
                            {header}
                        </CardHeader>
                        <CardTitle>
                            <Accordion open={openPastData} toggle={() => openPastData === code ? setOpenPastData('') : setOpenPastData(code)}>
                            <AccordionItem>
                                <AccordionHeader targetId={code}>
                                    Prev Logs
                                </AccordionHeader>
                                <AccordionBody accordionId={code}>
                                <div className="wizard-order">
                                <div className="v-line"></div>
                                    {data?.[code]?.map((data, index) => (
                                        <React.Fragment key={`cam-log-data${index}`}>
                                            <div className="step completed">
                                                <div className='ml-2 data-key' style={{minWidth: '100%'}}>{data?.message}</div>
                                                {/* <div className='ml-2 data-value' style={{minWidth: '100%'}}>To: {obj?.value}</div> */}
                                                <div className='ml-2' style={{fontSize: '10px'}}>Created On: {formatDate2(data?.createdAt)}, By: {data?.createdBy}</div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                                </AccordionBody>
                            </AccordionItem>
                               
                            </Accordion>
                        </CardTitle>
                        <CardBody style={{background: 'transparent'}} className='p-0'>
                            <JoditEditor
                                config={editorConfig}
                                value={content}
                                onChange={(content) => setContent(content)}
                            />
                        </CardBody>
                        <CardFooter className='text-center' style={{background: 'transparent'}}>
                            <Button onClick={onCancel} color='danger' className='me-2'>Cancel</Button>
                            <Button onClick={handleSubmit} color='success'>Save and Close</Button>
                        </CardFooter>
                    </Card>
                </div>
            </Draggable>
        </>
    )
}

export default Board;
