import { createSlice } from "@reduxjs/toolkit";

export const task = createSlice({
    name: 'taskList',
    initialState: {
      list: [],
      totalList: 0,
      loading: false,
      error: null
    },
    reducers: {
      getTaskList: (state, action) => {
        state.loading = true;
      },
      getTaskListSuccess: (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data;
        state.totalList = action?.payload?.count;
      },
      getTaskListFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      },
      updateTask: (state, action) => {
        state.loading = true;
      },
      updateTaskSuccess:(state, action) => {
        state.loading = false;
      },
      updateTaskFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }
     
    }
});



export const hotLead = createSlice({
  name: 'hotLead',
  initialState: {
    list: [],
    totalList: 0,
    loading: false,
    error: null,
    details: null
  },
  reducers: {
    getHotLeadList: (state, action) => {
      state.loading = true;
    },
    getHotLeadListSuccess: (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
      state.totalList = action?.payload?.count;
    },
    getHotLeadListError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    getHotLeadDetails: (state, action) => {
      state.details = null;
      state.loading = true;
      state.error = null;
    },
    getHotLeadDetailsSuccess: (state, action) => {
      state.details = action?.payload;
      state.loading = false;
    },
    getHotLeadDetailsError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    updateHotLeadDetails: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateHotLeadDetailsSuccess: (state, action) => {
      state.loading = false;
    },
    updateHotLeadDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
})

export const notificationTemplate = createSlice({
  name: 'notificationTemplateList',
  initialState: {
    list: [],
    totalList: 0,
    loading: false,
    error: null
  },
  reducers: {
    getNotificationTemplateList: (state, action) => {
      state.loading = true;
    },
    getNotificationTemplateListSuccess: (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
      state.totalList = action?.payload?.count;
    },
    getNotificationTemplateListFailure: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
   
  }
});


export const notificationType = createSlice({
  name: 'notificationTypeList',
  initialState: {
    list: [],
    totalList: 0,
    loading: false,
    error: null
  },
  reducers: {
    getNotificationTypeList: (state, action) => {
      state.loading = true;
    },
    getNotificationTypeSuccess: (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
      state.totalList = parseInt(action?.payload?.count);
      
    },
    getNotificationTypeListFailure: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    },
    updateNotificationType: (state, action) => {
      state.loading = true;
    },
    updateNotificationTypeSuccess:(state, action) => {
      state.loading = false;
    },
    updateNotificationTypeFailure: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
   
  }
});

export const notification = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    totalList: 0,
    loading: false,
    error: null
  },
  reducers: {
    getNotifications: (state, action) => {
      state.loading = true;
      state.error = null
    },
    getNotificationsSuccess: (state, action) => {
      state.loading = false;
      state.notifications = [...state.notifications, ...action.payload.data];
      state.totalList = action?.payload?.count;
    },
    getNotificationsError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

// actions
export const { 
  getTaskList, 
  getTaskListSuccess, 
  getTaskListFailure,
  updateTaskFailure,
  updateTaskSuccess,
  updateTask
} = task?.actions;

export const { 
  getHotLeadList,
  getHotLeadListSuccess,
  getHotLeadListError,
  getHotLeadDetails,
  getHotLeadDetailsError,
  getHotLeadDetailsSuccess,
  updateHotLeadDetails,
  updateHotLeadDetailsError,
  updateHotLeadDetailsSuccess
} = hotLead?.actions;

export const {
  getNotificationTemplateList,
  getNotificationTemplateListSuccess,
  getNotificationTemplateListFailure,
  updateNotificationTemplateFailure,
  updateNotificationTemplate,
  updateNotificationTemplateSuccess
} = notificationTemplate?.actions;

export const {
  getNotificationTypeList,
  getNotificationTypeListFailure,
  getNotificationTypeSuccess,
  updateNotificationType,
  updateNotificationTypeFailure,
  updateNotificationTypeSuccess
} = notificationType?.actions;

export const {
  getNotifications,
  getNotificationsSuccess,
  getNotificationsError,
  addNotification
} = notification?.actions;

// reducers
export const taskListReducer = task?.reducer;
export const notificationTemplateReducer = notificationTemplate?.reducer;
export const notificationTypeReducer = notificationType?.reducer;
export const hotLeadReducer = hotLead?.reducer;
export const notificationReducer = notification?.reducer;

