import React from 'react';
import { Button, Spinner } from 'reactstrap';

const LoadingButton = ({
  color, 
  isLoading=false, 
  onClick, 
  buttonContent,
  disabled=false
}) => {

  return (
    <Button color={color} disabled={isLoading || disabled} onClick={onClick}>
      {isLoading && 
        <Spinner size="sm">
          Loading...
        </Spinner>
      }
      <span>
        {buttonContent}
      </span>
    </Button>
  )
}

export default LoadingButton;
