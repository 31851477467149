import { createSlice } from "@reduxjs/toolkit";

export const dashboardData = createSlice({
    name: 'dashboardData',
    initialState: {
        loading: false,
        error: null,
        dashboardDetails:{},
        dashboardMonthData:{},
        dashboardCampaignData:{},
        dashboardTeamData:{},
        funnelData: {},
    },
    reducers: {
        getDashboardData: (state, action) => {
            state.loading = true;
        },
        getDashboardMonthData:(state, action) => {
            state.loading = true;
        },
        getDashboardCampaignData:(state, action) => {
            state.loading = true;
        },
        getDashboardTeamData: (state, action) => {
            state.loading = true;
        },
        getDashboardDataSuccess: (state, action) => {
            state.loading = false;
            state.dashboardDetails = action?.payload;
        },
        getDashboardMonthDataSuccess: (state, action) => {
            state.loading = false;
            state.dashboardMonthData = action?.payload;
        },
        getDashboardCampaignDataSuccess: (state, action) => {
            state.loading = false;
            state.dashboardCampaignData = action?.payload;
        },
        getDashboardTeamDataSuccess:(state, action) => {
            state.loading = false;
            state.dashboardTeamData = action?.payload;
        },
        getDashboardDataError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        // New actions for funnel data
        getFunnelData: (state, action) => {
            state.loading = true; 
        },
        getFunnelDataSuccess: (state, action) => {
            state.loading = false; 
            state.funnelData = action?.payload; // Store funnel data
        },
        getFunnelDataError: (state, action) => {
            state.loading = false; 
            state.error = action?.payload; 
        },
    }
});


export const { 
    getDashboardData,
    getDashboardMonthData,
    getDashboardCampaignData,
    getDashboardTeamData,
    getDashboardDataSuccess,
    getDashboardDataError,
    getDashboardMonthDataSuccess,
    getDashboardCampaignDataSuccess,
    getDashboardTeamDataSuccess,
    getFunnelData,          // New action for requesting funnel data
    getFunnelDataSuccess,   
    getFunnelDataError, 
     } = dashboardData?.actions;
  
  export const dashboardReducer = dashboardData?.reducer;