import React, { useEffect } from "react";
import RadialChart from "./RadialChart";
import { Card, CardBody, Col, Row } from "reactstrap";
import PieChart from "../../components/Charts/PieChart";
import useClass from "../../hooks/useClass";
import { getDashboardTeamData } from "../../store/dashboard/dashboard";
import { useSelector } from "react-redux";
import { monthData } from "../../constants/constants";

const GeoLeads = () => {

  const { toaster, dispatch } = useClass();
  const year = new Date().getFullYear();

  const {dashboardTeamData} = useSelector((store) => store?.dashboardData);

  const getDashboardTeams = async (month) => {
     dispatch(getDashboardTeamData({month,year}))
  }

  const getCurrentMonthNumber = () => {
    const now = new Date();
    return now.getMonth() + 1; 
  };

  useEffect(() => {
    getDashboardTeams(getCurrentMonthNumber())
  }, []);

  const dataArray = [
    ...(dashboardTeamData?.customerCountByTeams || []),
    ...(dashboardTeamData?.jounrneyCompletedCountByTeams || []),
    ...(dashboardTeamData?.leadsCountByTeams || []),
  ];

  const aggregatedData = dataArray.reduce((acc, { team, count }) => {
    const countNumber = parseInt(count, 10);
    acc[team] = (acc[team] || 0) + countNumber;
    return acc;
  }, {});

  const result = Object.entries(aggregatedData).map(([team, count]) => ({
    team,
    count: count.toString(),
  }));

  const teamNames = result.length ? result.map(({ count }) => parseInt(count, 10)) : [0];
  const teamCounts = result.length ? result.map(({ team }) => team) : ['No Data'];
  const totalCustomer = dashboardTeamData?.customerCountByTeams?.reduce((acc,curr)=>Number(curr?.count)+acc,0);
  const totalLeads = dashboardTeamData?.leadsCountByTeams?.reduce((acc,curr)=>Number(curr?.count)+acc,0);
  const totalCompletedJourney = dashboardTeamData?.jounrneyCompletedCountByTeams?.reduce((acc,curr)=>Number(curr?.count)+acc,0);

  const SocialSourceData = [
    {
      title: "Total lead",
      count: totalLeads,
      icon: 'fas fa-user'
    },
    {
      title: "Customer",
      count: totalCustomer,
      icon: 'fas fa-user-edit'
    },
    {
      title: "Journey complete",
      count: totalCompletedJourney,
      icon: 'fas fa-user-check'
    }
  ]

  return (
    <>
      <Col xl={4} md={6}>
        <Card className="h-100">
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Team Numbers</h5>
              </div>
              <div className="flex-shrink-0">
                <select defaultValue={getCurrentMonthNumber()} onChange={(e)=>getDashboardTeams(e.target.value)} className="form-select form-select-sm mb-0 my-n1">
                  {monthData?.map((item, key) => (
                    <option key={key} value={item?.number}>
                      {item?.month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <PieChart teamNames={teamNames} teamCounts ={teamCounts} />
        </CardBody>
        <CardBody>
            <Row>
              {SocialSourceData.map((item, key) => (
                <div key={key} className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                      className={
                        "avatar-title rounded-circle font-size-18 bg-" +
                        item.bgcolor
                      }
                      >
                        <i className={item.icon}></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">{item.title}</h5>
                    <p className="text-muted mb-0">{item.count}</p>
                  </div>
                </div>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default GeoLeads;
