import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import useClass from '../../hooks/useClass';
import { useSelector } from 'react-redux';
import { getTeamList } from '../../store/admin/adminData';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';

const Columns = () => {

    const {toaster, dispatch, permission, adminServer} = useClass();
    const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        let timeOut;
        timeOut = setTimeout(() => {
            if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
                dispatch(getTeamList({search:searchQuery}));
            }
        }, 500);

        return () => {
          clearTimeout(timeOut)
        }
    }, [searchQuery]);

    const handleTeamAssignment = (team, leadId) => {
        return async () => {
          try {
            const result = await toaster.confirmation('Assign', `Assign this Lead to ${team?.name}`);
            if(result?.isConfirmed){
              const payload = {teamId: team.id, leadIds: [parseInt(leadId)]}
              const response = await adminServer.assignLead(payload);
              if(response?.data?.success){
                window.location.reload();
              } else {
                throw new Error(response?.data?.message);
              }
            }
          } catch(error) {
            toaster.show(true, error?.message);
          }
        }
    }

    const handleUserAssignment = (user, leadId, teamId) => {
        return async () => {
          try {
            const result = await toaster.confirmation('Assign', `Assign Lead to ${user.name}`);
            if(result?.isConfirmed){
              const payload = {userId: user.userId, teamId,leadIds: [parseInt(leadId)]}
              const response = await adminServer.assignLead(payload);
              if(response?.data?.success){
                window.location.reload();
              } else {
                throw new Error(response?.data?.message);
              }
            }
          } catch(error) {
            toaster.show(true, error?.message);
          }
        }
    }

    const getTeamMembers = (teamId) => {
        let members = [];
        teamList?.forEach((team) => {
            if(team?.id === teamId){
                members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}));
            }
        })
        return members;
    }

    const handleInput = (e) => {
        setSearchQuery(e.target.value);
    }

    const data = [
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => (permission?.HOT_LEADS?.DETAILS?.VIEW_HOT_LEAD_DETAILS ? <Link to={`/hot_leads/details/${row?.id}`}>{row?.name || '-'}</Link> : row?.name),
            sortable: false,
            width: '200px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Entity Name</span>,
            selector: row => row?.entityName || '-',
            sortable: false,
            width: '300px',
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>Product Name</span>,
        //     selector: row => row?.application?.productName || '-',
        //     sortable: false,
        //     width: '300px'
        // },
        {
            name: <span className='font-weight-bold fs-13'>Mobile Number</span>,
            selector: row => row?.mobile || '-',
            sortable: false,
            width: '150px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Team name / Assigned To</span>,
            cell: (row) => <>
                    <UncontrolledDropdown className="dropdown d-inline-block">
                         <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <Link>{row?.teamName || 'Unassigned'}</Link>
                         </DropdownToggle>
                         <DropdownMenu className="dropdown-menu-end">
                            <div className='d-flex'>
                              <div className='m-2 mr-2'>
                                <input
                                  type="text" 
                                  placeholder="Search..." 
                                  value={searchQuery} 
                                  onChange={handleInput}
                                />
                              </div>
                              {teamListLoading && <div className='d-flex align-items-center me-2'><Spinner size="sm" /></div>}
                            </div>
                            {teamList?.map((team, index) => (
                                <React.Fragment key={`app-col-teams${index}`}>
                                    <DropdownItem onClick={handleTeamAssignment(team, row?.id)}>{team?.name}</DropdownItem>
                                </React.Fragment>
                            ))}
                         </DropdownMenu>
                    </UncontrolledDropdown>
                    &nbsp;/ &nbsp;
                    <UncontrolledDropdown className="dropdown d-inline-block">
                         <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <Link>{row?.assignedTo  || 'Unassigned'}</Link>
                         </DropdownToggle>
                         <DropdownMenu className="dropdown-menu-end">
                            {getTeamMembers(row?.teamId)?.map((member, index) => (
                                <React.Fragment key={`app-col-ass-memb${index}`}>
                                    <DropdownItem onClick={handleUserAssignment(member, row?.id, row?.teamId)}>{member?.name}</DropdownItem>
                                </React.Fragment>
                            ))}
                         </DropdownMenu>
                    </UncontrolledDropdown>
                </>,
            sortable: false,
            width: '250px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Assisted Link</span>,
            selector: row => (permission?.HOT_LEADS?.LIST?.CREATE_NEW_HOT_LEAD ? <a href={row?.assistedUrl} target="_blank" rel="noopener noreferrer">{'Journey Link' || '-'}</a> : 'Link'),
            sortable: false,
            width: '150px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Created Date</span>,
            selector: row => new Date(row?.createAt).toLocaleDateString() || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.status) {
                    case "INCOMPLETE":
                        return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                }
            },
        }
    ];

    return data;
}

export default Columns;
