import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CampaignFlows from '../../Pages/Campaign/CampaignFlow/CampaignFlows';
import Details from '../../Pages/Campaign/CampaignFlow/Details';

const CampaignFlow = () => {
  return (
    <Routes>
        <Route path='/list' element={<CampaignFlows/>} />
        <Route path='/details/:campaignId' element={<Details />} />
    </Routes>
  )
}

export default CampaignFlow;