import { createSlice } from "@reduxjs/toolkit";

export const loanAction = createSlice({
  name: 'loanAction',
  initialState: {
    data: {
      action: null,
      loanUid: null,
      productCode: null,
      applicationId: null,
    },
    loading:false,
    invoiceDetails:{},
  },
  reducers: {
    updateLoanAction: (state, action) => {
      state.data = action?.payload;
    },
    getInvoiceDetailsAction: (state, action) => {
      state.loading = true;
    },
    getInvoiceDetailsActionSuccess: (state, action) => {
      state.invoiceDetails = action?.payload;
      state.loading = false;
    },
    getInvoiceDetailsActionFailure: (state, action) => {
      state.loading = false;
    }
  }
});

export const bureauReport = createSlice({
  name: 'bureauReport',
  initialState: {
    loading: false,
    error: null,
    report: null
  },
  reducers: {
    getBureauReport: (state, action) => {
      state.loading = true;
      state.error = null;
      state.report = {};
    },
    getBureauReportSuccess: (state, action) => {
      state.loading = false;
      state.report = action?.payload;
    },
    getBureauReportError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

export const { 
  updateLoanAction, 
  getInvoiceDetailsAction, 
  getInvoiceDetailsActionSuccess, 
  getInvoiceDetailsActionFailure 
} = loanAction?.actions;

export const {
  getBureauReport,
  getBureauReportSuccess,
  getBureauReportError
} = bureauReport?.actions;

export const loanActionReducer = loanAction?.reducer;
export const bureauReportReducer = bureauReport?.reducer;