import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        // {
        //     name: <span className='font-weight-bold fs-13'>SR No.</span>,
        //     selector: row => row?.index || '-',
        //     sortable: false
        // },
        {
            name: <span className='font-weight-bold fs-13'>Code</span>,
            selector: row =>  <Link to={`/template/details/${row?.id}`}>{row.code}</Link> || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Type</span>,
            selector: row => row?.type || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Is Active</span>,
            sortable: false,
            selector: (row) => {
                switch (`${row?.isActive}`) {
                    case "false":
                        return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                    case "true":
                        return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Created By</span>,
            selector: row => row?.createdBy || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Created At</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Updated At</span>,
            selector: row => new Date(row?.updatedAt).toLocaleDateString() || '-',
            sortable: false
        }
    ];

    return data;
}

export default columns;
