import React, { useEffect, useState } from 'react';
import { Modal, ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, Spinner } from "reactstrap";
import LoadingButton from '../../components/Common/LoadingButton';
import useClass from '../../hooks/useClass';
import TextInput from '../../components/text-input/TextInput';
import { useSelector } from 'react-redux';
import { generateToken } from '../../utils/encryption';
import Dropdown from '../../components/Dropdown/Dropdown';
import { defaultCityList, entityType, typeOfBusiness } from '../../constants/journeyKey';
import AsyncDropdown from '../../components/Dropdown/AsyncDropdown';
import { getProductList } from '../../store/admin/adminData';
import { updateHotLeadDetails } from '../../store/user/userSlice';

const Create = (props) => {

  const userDetails = useSelector((state) => (state?.userDetails?.details));
  const { list: productList } = useSelector((state) => (state?.product));
  const { loading, details } = useSelector((state) => (state?.hotLead));
  const { toaster, server, dispatch, permission } = useClass();
  const {isOpen, setIsOpen, isUpdating=false} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    mobile: '',
    details: {},
  });
  const [calledLead, setCalledLead] = useState(false);
  const [openButton, setOpenButton] = useState(false);

  useEffect(() => {
    dispatch(getProductList({page: 1, perPage: 30, search: ''}));
  }, []);

  useEffect(() => {
    if(details && isUpdating){
      setForm(details);
    }
  }, [details])

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleDetails = (key, value) => {
    setForm((prev) => ({
      ...prev,
      details: {
        ...prev?.details,
        [key]: value
      }
    }))
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleLeadCreation = async (redirect, sendLink) => {
    try{
      setIsLoading(true);
      if(validateForm()){
        let payload = {
          name: form?.name,
          email: form?.email,
          mobile: form?.mobile,
          leadSource: 'CMS',
          status: 'INCOMPLETE',
          calledLead: form?.calledLead,
          sendMessage: sendLink,
          details: form?.details
        }
        const response = await server.createHotLead(payload);
        setIsLoading(false);
        if(response?.data?.success){
          handleModelClose();
          toaster.show(false, 'Hot lead created successfully');
          if(redirect){
            navigateToCos();
          } else {
            window.location.reload();
          }
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please fill all mandatory fields.');
      }
    } catch(error){
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  }

  const updateLeadDetails = () => {
    // let payload = {
    //   name: form?.name,
    //   email: form?.email,
    //   mobile: form?.mobile,
    //   leadSource: 'CMS',
    //   status: 'INCOMPLETE',
    //   calledLead: form?.calledLead,
    //   sendMessage: form?.sendMessage,
    //   details: form?.details
    // }
    // dispatch(updateHotLeadDetails({payload, id: details?.id}));
  
    const dirtyFields = Object.keys(form).reduce((acc, key) => {
      if (form[key] !== details[key]) {
        acc[key] = form[key];
      }
      return acc;
    }, {});

    const dirtyDetails = Object.keys(form.details || {}).reduce((acc, key) => {
      if ((form.details || {})[key] !== (details.details || {})[key]) {
        acc[key] = form.details[key];
      }
      return acc;
    }, {});

    let payload = {
      ...dirtyFields
    };

    if(dirtyFields?.details?.businessType && !form?.details?.businessType?.label?.includes('Fleet')){
      payload = {
        ...payload,
        details: {
          ...payload?.details,
          fleetSize: 'NA',
          evsInFleet: 'NA'
        }
      }
    }

    if(form?.details?.businessType?.label?.includes('Fleet') && form?.details?.fleetSize === 'NA' && form?.details?.evsInFleet === 'NA'){
      toaster.show(true, 'Please enter fleet size and evs in fleet.');
      return;
    }

    if(Object.keys(dirtyDetails).length > 0){
      payload = {
        ...payload,
        details: {...payload?.details, ...dirtyDetails}
      }
    }

    if (Object.keys(payload).length > 0) {
      dispatch(updateHotLeadDetails({ payload, id: details?.id }));
    } else {
      toaster.show(true, 'Make any change to update.');
    }
  }

  const validateForm = () => {
    let fields = ['name', 'email', 'mobile'];
    if(calledLead){
      fields = [...fields, 
        'entityType', 
        'entityName', 
        'businessType', 
        'yearOfIncorporation',
        'serviceCities',
        'currentFYRevenue',
        'currentFYPAT',
        'lastFYRevenue',
        'lastFYPAT',
        'selectedProducts',
        'loanAmount',
        'deadline',
        'notes'
      ]
    }
    if(form?.details?.businessType?.label?.includes('Fleet')){
      fields = [...fields,
        'fleetSize',
        'evsInFleet'
      ]
    }
    const result = fields?.every((key) => {
      console.log(form[key], key, 'tetsing lead craetion')
      return form[key] && form[key] !== "";
    });
    return result;
  }

  const navigateToCos = () => {
    const cosUrl = process.env.REACT_APP_COS_URL;
    const {emailToken, mobileToken} = generateToken(form?.mobile, userDetails?.email);
    window.open(`${cosUrl}/login?e=${emailToken}&m=${mobileToken}`, '_blank');
  }

  const fetchCityList = (search='', callback) => {
    callback();
  }

  return (
    <>
      <Modal 
        size='lg'
        isOpen={isOpen}
        toggle={() => {setIsOpen(!isOpen)} }
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {isUpdating ? 'Update Lead' : 'Create Lead'}
          </h5>
          <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className='row'>
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12'}
              label={'Contact Person Name'}
              required={true}
              name={'name'}
              value={form?.name}
              handleInput={handleInput}
              type={'text'}
              disabled={isUpdating}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12'}
              label={'Email'}
              required={true}
              name={'email'}
              value={form?.email}
              handleInput={handleInput}
              type={'text'}
              disabled={isUpdating}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12'}
              label={'Mobile No.'}
              required={true}
              name={'mobile'}
              value={form?.mobile}
              handleInput={handleInput}
              type={'text'}
              maxLength={10}
              disabled={isUpdating}
            />
          </div>
          <div className='mt-4'>
            <input checked={calledLead} type='checkbox' value={calledLead} onChange={(e) => setCalledLead(e.target.checked)} />
            {" "}Did you meet/call the lead.
          </div>
          {calledLead && <div className='row mt-4'>
            <Dropdown
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Entity type'}
              required={true}
              options={entityType}
              handleInput={handleDetails}
              name={'entityType'}
              value={form?.details?.entityType}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Entity name'}
              required={true}
              name={'entityName'}
              value={form?.details?.entityName}
              handleInput={handleDetails}
              type={'text'}
            />
            <Dropdown
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Business type'}
              required={true}
              options={typeOfBusiness}
              handleInput={handleDetails}
              name={'businessType'}
              value={form?.details?.businessType}
            />
            <Dropdown
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Year of incorporation'}
              required={true}
              options={Array.from({ length: 100 }, (_, index) => ({label: new Date().getFullYear() - index, value: new Date().getFullYear() - index}))}
              handleInput={handleDetails}
              name={'yearOfIncorporation'}
              value={form?.details?.yearOfIncorporation}
            />
            <AsyncDropdown
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Top service cities'}
              required={true}
              isMulti={true}
              defaultOptions={defaultCityList}
              handleInput={handleDetails}
              loadOptions={fetchCityList}
              name={'serviceCities'}
              value={form?.details?.serviceCities}
            />
            {form?.details?.businessType?.label?.includes('Fleet') && <><TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Fleet size'}
              required={true}
              name={'fleetSize'}
              value={form?.details?.fleetSize}
              handleInput={handleDetails}
              type={'number'}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'EV(s) in Fleet'}
              required={true}
              name={'evsInFleet'}
              value={form?.details?.evsInFleet}
              handleInput={handleDetails}
              type={'number'}
            /></>}
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
              label={`Revenue FY${new Date().getFullYear()} - ${new Date().getFullYear() + 1}`}
              required={true}
              name={'currentFYRevenue'}
              value={form?.details?.currentFYRevenue}
              handleInput={handleDetails}
              type={'number'}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
              label={`PAT FY${new Date().getFullYear()} - ${new Date().getFullYear() + 1}`}
              required={true}
              name={'currentFYPAT'}
              value={form?.details?.currentFYPAT}
              handleInput={handleDetails}
              type={'number'}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
              label={`Revenue FY${new Date().getFullYear()-1} - ${new Date().getFullYear()}`}
              required={true}
              name={'lastFYRevenue'}
              value={form?.details?.lastFYRevenue}
              handleInput={handleDetails}
              type={'number'}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-4'}
              label={`PAT FY${new Date().getFullYear()-1} - ${new Date().getFullYear()}`}
              required={true}
              name={'lastFYPAT'}
              value={form?.details?.lastFYPAT}
              handleInput={handleDetails}
              type={'number'}
            />
            <Dropdown
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Products'}
              required={true}
              options={productList?.map((prod) => ({
                ...prod,
                label: prod?.name,
                value: prod?.code
              }))}
              handleInput={handleDetails}
              name={'selectedProducts'}
              value={form?.details?.selectedProducts}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Loan amount'}
              required={true}
              name={'loanAmount'}
              value={form?.details?.loanAmount}
              handleInput={handleDetails}
              type={'number'}
            />
            <TextInput 
              customClass={'col-lg-4 col-md-6 col-sm-12 mb-2'}
              label={'Deadline for delivery'}
              required={true}
              name={'deadline'}
              value={form?.details?.deadline}
              handleInput={handleDetails}
              type={'date'}
            />
            <div className="col-lg-8 col-md-8 col-sm-12 mb-2">
              <div className='text-input'>
                <div className='heading'>Notes<span>*</span></div>
                <div className="input-group">
                  <textarea
                    className='form-control'
                    placeholder='Any comment...'
                    value={form?.details?.notes}
                    onChange={(e) => handleDetails('notes', e.target.value)}
                    cols={8}
                    rows={3}
                  />
                </div>
              </div>
            </div>
          </div>}
          {/* <div className='mt-4'>
            <input type='checkbox' value={form?.sendMessage} onChange={(event) => {handleInput('sendMessage', event.target.checked)}} />
            {" "}Send link to customer.
          </div> */}
        </div>
        <div className="modal-footer">
          {isUpdating ?  <LoadingButton
            buttonContent={'Update'}
            isLoading={loading}
            onClick={updateLeadDetails}
            color={'success'}
          /> : <>
              <ButtonDropdown
                isOpen={openButton}
                toggle={() => setOpenButton(!openButton)}
              >
                <DropdownToggle caret color="primary">
                  Create {isLoading && <Spinner size='sm'/>}<i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleLeadCreation(false, false)}>Create</DropdownItem>
                  <DropdownItem onClick={() => handleLeadCreation(true, false)}>Create and Assist</DropdownItem>
                  <DropdownItem onClick={() => handleLeadCreation(false, true)}>Create and send link to lead</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
          </>}
        </div>
      </Modal>
    </>
  )
}

export default Create;
