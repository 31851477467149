import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useSelector } from "react-redux";
import { getCampaignFlowDetails } from "../../../store/campaign/campaignFlow";

const Details = () => {

  const { toaster, permission, dispatch } = useClass()
  const { campaignId } = useParams();

  const {detail} = useSelector((state) => (state?.campaignFlow));


  useEffect(() => {
    dispatch(getCampaignFlowDetails(campaignId));
  }, []);

  
  return (
    <div>
      <Row
        style={{ paddingBottom: "10px" }}
        className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Campaign_flow" breadcrumbItem="Campaign Flow Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader>
                  <div className="section-heading">Campaign Flow Details</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Campaign Name</Row>
                      <Row className="data-value me-4">{detail?.name}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Channel</Row>
                      <Row className="data-value me-4">{detail?.channel}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Description</Row>
                      <Row className="data-value me-4">
                        {detail?.description}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Schedule At</Row>
                      <Row className="data-value me-4">
                        {detail?.schedule}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Trigger Date</Row>
                      <Row className="data-value me-4">
                        {detail?.triggerDate}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created By</Row>
                      <Row className="data-value me-4">
                        {detail?.createdBy}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created Date</Row>
                      <Row className="data-value me-4">
                        {new Date(detail?.createdAt).toLocaleDateString()}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Details;
