import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "reactstrap";
import LoadingButton from "../../../components/Common/LoadingButton";
import useClass from "../../../hooks/useClass";
import TextInput from "../../../components/text-input/TextInput";
import { useSelector } from "react-redux";
import Dropdown from "../../../components/Dropdown/Dropdown";
import {
  createSegment,
  getSegmentFilterQueryData,
  getSegmentTableColumn,
} from "../../../store/campaign/segment";
import { formatQuery, QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

const Create = ({ isOpen, setIsOpen }) => {
  const { toaster, dispatch } = useClass();
  const [showTables, setShowTables] = useState(true);
  const [dbTable, setDbTable] = useState("");
  const [ruleField, setRuleField] = useState([]);
  const { filterQueryData } = useSelector((state) => state?.campaignSegment);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ name: "", description: "" });
  const [query, setQuery] = useState({ combinator: "and", rules: [] });
  const { tableColumnList } = useSelector((state) => state.campaignSegment);

  useEffect(() => {
    dispatch(getSegmentTableColumn());
  }, [dispatch]);

  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handleSegmentCreate = async () => {
    const payload = {
      name: form.name,
      description: form.description,
      tableName: dbTable,
      filterQueryJson: ruleField,
      filterQueryWhere: formatQuery(query, "sql"),
    };
    dispatch(createSegment(payload));
  };

  const handleTableInput = (key, value) => {
    setDbTable(value?.label);
  };

  const handleShowRules = () => {
    if (!dbTable.trim()) {
      toaster.show(true, "Please select a table");
      return;
    }
    const findColumns = tableColumnList?.find(
      (column) => column?.tableName === dbTable
    );
    setRuleField(
      findColumns?.columnNames?.map((column) => ({
        name: column,
        label: column,
      }))
    );
    setShowTables(false);
  };

  const validateForm = () => {
    return form.name !== "" && form.description !== "";
  };

  const handleInput = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleShowQueryData = () => {
    const whereQuery = formatQuery(query, "sql");
    if (!whereQuery || whereQuery.trim() === "(1 = 1)") {
      toaster.show(true, "Please select any condition");
      return;
    }
    dispatch(getSegmentFilterQueryData({ whereQuery, dbTable }));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        centered
        className="modal-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Create Segment</h5>
          <button
            type="button"
            onClick={handleModelClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {showTables ? (
          <div className="mt-4 p-3">
            <Dropdown
              label="Select the table"
              name="dbTable"
              value={dbTable?.label}
              required={true}
              handleInput={handleTableInput}
              options={tableColumnList.map((table) => ({
                label: table?.tableName,
                value: table?.tableName,
              }))}
            />
            <Button color="primary" onClick={handleShowRules}>
              Move to Select Column
            </Button>
          </div>
        ) : (
          <div>
            <div className="modal-body">
              <h6>Create Rules Here for ({dbTable})</h6>
              <QueryBuilder
                fields={ruleField}
                query={query}
                onQueryChange={setQuery}
              />
              {filterQueryData.length > 0 && (
                <Row>
                  <Col>
                    <TextInput
                      label="Name"
                      required={true}
                      name="name"
                      value={form.name}
                      handleInput={handleInput}
                      type="text"
                    />
                  </Col>
                  <Col>
                    <TextInput
                      label="Description"
                      required={true}
                      name="description"
                      value={form.description}
                      handleInput={handleInput}
                      type="text"
                    />
                  </Col>
                </Row>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={handleModelClose}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Go back
              </button>
              {validateForm() ? (
                <LoadingButton
                  buttonContent="Create"
                  isLoading={isLoading}
                  onClick={handleSegmentCreate}
                  color="success"
                />
              ) : (
                <Button color="primary" onClick={handleShowQueryData}>
                  View Response
                </Button>
              )}
            </div>

            <Container>
              <div className="table-responsive mt-4 p-3">
                <Table bordered>
                  <thead>
                    <tr>
                      {filterQueryData.length > 0 &&
                        Object.keys(filterQueryData[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filterQueryData.map((user) => (
                      <tr key={user.id}>
                        {Object.values(user).map((value, idx) => (
                          <td key={idx}>{String(value)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Container>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Create;
