import React, { useEffect, useMemo, useState } from 'react';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    Spinner, 
    Row, 
    Nav, 
    NavItem, 
    NavLink, 
    TabContent, 
    TabPane, 
    Accordion, 
    AccordionBody, 
    AccordionItem, 
    AccordionHeader,
    Input,
    Button,
    Col
} from 'reactstrap';
import useClass from '../../hooks/useClass';
import {
    entityData, 
    entityDataNumbers, 
    entityDirectorsData, 
    entityDocumentsData, 
    fieldStatusConstants, 
    fieldVerificationDropdown, 
    reKycFieldData, 
    rupeeSymbol
} from '../../utils/constants';
import DocModal from '../../components/Document-Viewer/DocModal';
import Dropdown from '../../components/Dropdown/Dropdown';
import ActionDropdown from '../../components/Dropdown/ActionDropdown';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBureauReport } from '../../store/loan/loanAction';
import CreditScore from './credit-analysis/CreditScore';
import { formatDate3 } from '../../utils/helper';
import { sendLinkForKYC } from '../../store/application/customer';
import { useParams } from 'react-router-dom';
import { getAdditionalDocList, removeAdditionalDocField, updateAdditionalDocFields } from '../../store/application/applicationAction';
import NewAdditionalDoc from './Modal/NewAdditionalDoc';
import BankStatement from './credit-analysis/BankStatement';

const Entity = ({
    entityFinancialData,
    handleFieldAction, 
    entityDetails
}) => {

    const { toaster, dispatch } = useClass();
    const { customerCode } = useParams();
    const { fieldStatus, reKycFields } = useSelector((state) => (state?.actionFields));
    const { loading, error, report } = useSelector((state) => (state?.bureauReport))
    const { additionalDocs } = useSelector((state) => (state?.actionFields));
    const { additionalDocList } = useSelector((state) => (state?.applicationAction));
    const [open, setOpen] = useState('');
    const [activeDirectorTab, setActiveDirectorTab] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [viewedDoc, setViewedDoc] = useState({});
    const [isAdditionalDocButtonClicked, setIsAdditionalDocButtonClicked] = useState(false);
    const [viewCreditReport, setViewCreditReport] = useState(false);
    const [viewedDirector, setViewedDirector] = useState('');
    const [revenueBreakup, setRevenueBreakup] = useState([]);
    const [topDebtors, setTopDebtors] = useState({});
    const [activeTopDebtorsTab, setActiveTopDebtorsTab] = useState(0);
    const [activeTopPartnersTab, setActiveTopPartnersTab] = useState(0);
    const [newAddDoc, setNewAddDoc] = useState(false);
    const [viewBankStatement, setViewBankStatement] = useState(false);

    useEffect(() => {
        dispatch(getAdditionalDocList());
    }, [])

    useEffect(() => {
        if(!loading && !error && report && viewedDirector){
            setViewCreditReport(true);
        }
    }, [loading]);

    useEffect(() => {
        if(entityFinancialData?.length > 0){
            entityFinancialData?.forEach((obj) => {
                if(obj?.fieldKey === 'revenueBreakup'){
                    setRevenueBreakup(JSON.parse(obj?.fieldValue));
                } else if(obj?.fieldKey === 'debtorDetails'){
                    setTopDebtors(JSON.parse(obj?.fieldValue));
                }
            });
        }
    }, [entityFinancialData])

    const toggleDirectorTab = (tab) => {
        return () => {
          if(activeDirectorTab !== tab){
            setActiveDirectorTab(tab);
          }
        }
    }

    const toggleTopDebtorsTab = (tab) => {
        return () => {
            setActiveTopDebtorsTab(tab);
        }
    }

    const toggleTopPartnersTab = (tab) => {
        return () => {
            setActiveTopPartnersTab(tab);
        }
    }

    const toggle = (id) => {
        if (open === id) {
          setOpen('');
        } else {
          setOpen(id);
        }
    };

    const handleImageExpand = (link, label) => {
        return () => {
            window.open(link, '_blank');
            // setViewedDoc({link, label});
            // setIsOpen(true);
        }
    }

    const handleAdditionDocInput = (key, value) => {
        if(value?.value === 'others') {
            setNewAddDoc(true);
            return;
        }
        if(!additionalDocs.some((obj) => (obj?.field == value?.value))){
            setIsAdditionalDocButtonClicked(false);
            dispatch(updateAdditionalDocFields({field: value?.value, value}))
        } else {
            toaster.show(true, 'Document already selected');
            setIsAdditionalDocButtonClicked(false);
        }
    }

    const handleAdditionalDocButton = (type, key=null) => {
        return () => {
            if(type === 'add'){
                setIsAdditionalDocButtonClicked(true);
            } else {
                dispatch(removeAdditionalDocField(key));
            }
        }
    }

    const fetchBureauOfDirector = (dir) => {
        return () => {
            setViewedDirector(dir?.name);
            dispatch(getBureauReport(dir?.code));
        }
    }

    const sendLinkToDirectorForKYC = (dir) => {
        return async () => {
            const result = await toaster.singleInputToaster('Send', `Send link to ${dir?.name} for KYC`);
            if(result?.isConfirmed){
                const payload = {
                    "mobileNumber": result?.value,
                    "name": dir?.name,
                    "directorCode": dir?.code,
                    "customerCode": customerCode
                }
              dispatch(sendLinkForKYC(payload))
            }
        }
    }

    const toggleNewDoc = () => {
        setNewAddDoc(false);
        setIsAdditionalDocButtonClicked(false);
    }
    
    return (
        <>
            <Card>
                <CardHeader>
                    <div className='section-heading'>Entity: {entityDetails?.businessName}</div>
                </CardHeader>
                <CardBody>
                    <Row>
                        {Object.keys(entityDetails)?.map((key, index) => {
                            if(entityData[key]){
                                return (
                                    <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-data${index}`}>
                                        <div className='data-key ms-4 gap-4'>
                                            <span>
                                                {entityData[key]}
                                            </span>
                                        </div>
                                        <div className='data-value me-4'>
                                            <span>{entityDetails?.[key]?.toLocaleString() || '-'}</span>
                                            {reKycFieldData?.includes(key) && 
                                                <ActionDropdown
                                                    color={fieldStatusConstants?.[fieldStatus?.[key]]?.color}
                                                    className={fieldStatusConstants?.[fieldStatus?.[key]]?.className}
                                                    options={fieldVerificationDropdown}
                                                    onClick={(action) => handleFieldAction(action, key)}
                                                />
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        {entityFinancialData?.map((obj, index) => {
                            if(entityData[obj?.fieldKey]){
                                return (
                                    <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-finacial-${index}`}>                 
                                        <div className='data-key ms-4'>
                                            <span>
                                                {entityData[obj?.fieldKey]}
                                            </span>
                                        </div>
                                        <div className='data-value me-4'>
                                            {rupeeSymbol?.includes(obj?.fieldKey) && '₹'} {obj?.fieldValue?.toLocaleString() || '-'}
                                            {reKycFieldData?.includes(obj?.fieldKey) &&
                                                <ActionDropdown
                                                    color={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.color}
                                                    className={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.className}
                                                    options={fieldVerificationDropdown}
                                                    onClick={(action) => handleFieldAction(action, obj?.fieldKey)}
                                                />
                                            }
                                        </div>
                                    </div>
                                
                                )
                            }
                        })}
                        <hr />
                        {entityFinancialData?.map((obj, index) => {
                            if(entityDataNumbers[obj?.fieldKey]){
                                return (
                                    <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-data-number-${index}`}>                 
                                        <div className='data-key ms-4'>
                                            <span>
                                                {entityDataNumbers[obj?.fieldKey]}
                                            </span>
                                        </div>
                                        <div className='data-value me-4'>
                                            {rupeeSymbol?.includes(obj?.fieldKey) && '₹'} {obj?.fieldValue ? parseInt(obj?.fieldValue)?.toLocaleString() : '-'}
                                            {reKycFieldData?.includes(obj?.fieldKey) &&
                                                <ActionDropdown
                                                    color={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.color}
                                                    className={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.className}
                                                    options={fieldVerificationDropdown}
                                                    onClick={(action) => handleFieldAction(action, obj?.fieldKey)}
                                                />
                                            }
                                        </div>
                                    </div>
                                
                                )
                            }
                        })}
                        <hr />
                        {entityDetails?.entityDoc?.length > 0 && entityDetails?.entityDoc?.map((obj, index) => {
                            if(entityDocumentsData?.[obj?.docType]) return (<div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`entity-doc-${index}`}>
                                <div className='data-key ms-4'>
                                    <span>
                                        {entityDocumentsData?.[obj?.docType]}
                                    </span>
                                </div>
                                <div className='data-value me-4'>
                                    {obj?.docType !== 'bankStatement' ? <span className='clickable me-2'>
                                        <i className='fas fa-external-link-alt' onClick={handleImageExpand(obj?.presignUrl, entityDocumentsData?.[obj?.docType])} />
                                    </span> :
                                    <span className='clickable'>
                                        <i className='fas fa-chart-line' onClick={() => setViewBankStatement(!viewBankStatement)}/>
                                    </span>}
                                    <ActionDropdown
                                        color={fieldStatusConstants?.[fieldStatus?.[obj?.docType]]?.color}
                                        className={fieldStatusConstants?.[fieldStatus?.[obj?.docType]]?.className}
                                        options={fieldVerificationDropdown}
                                        onClick={(action) => handleFieldAction(action, obj?.docType)}
                                    />
                                </div>
                            </div>
                        )
                        })}
                        {additionalDocs?.map((obj, index) => (
                            <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`additional-doc-${index}`}>
                                <Dropdown
                                    value={obj?.value}
                                    customClass='addition-doc-dropdown'
                                    options={additionalDocList}
                                    handleInput={handleAdditionDocInput}
                                    name={'additionalDoc'}
                                    isClearable={false}
                                />
                                <Button 
                                    onClick={handleAdditionalDocButton('remove', obj?.field)}
                                    className='btn-close addtional-doc-button-remove'
                                />
                            </div>
                        ))}
                        <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                            {isAdditionalDocButtonClicked ? <Dropdown
                                customClass='addition-doc-dropdown'
                                options={additionalDocList}
                                handleInput={handleAdditionDocInput}
                                name={'additionalDoc'}
                            /> :
                            <Button 
                                onClick={handleAdditionalDocButton('add')} 
                                className='addtional-doc-button'
                                color='primary' 
                                outline
                            >
                                Request more doc
                            </Button>}
                        </div>
                    </Row>
                    <hr />
                    {entityDetails?.entityDirectors?.length > 0 && 
                        <Row className='ps-4 pe-4'>
                            <Col style={{fontWeight: 'bold'}} className='mb-2'>Directors</Col>
                            <Nav tabs>
                                {entityDetails?.entityDirectors?.map((director, index) => (
                                    <NavItem key={index}>
                                        <NavLink 
                                            style={{color: activeDirectorTab === index && 'green' }} 
                                            className={`${activeDirectorTab === index && 'active'} clickable`} 
                                            onClick={toggleDirectorTab(index)}
                                        >
                                          <i className="ri-user-line me-1 align-middle"> </i>{" "}
                                          {director?.name}
                                          {director?.kycStatus === 'VERIFIED' && <i style={{marginLeft: '0.4rem'}} className='fas fa-check-circle'></i>}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeDirectorTab} className="p-3">
                                <TabPane tabId={activeDirectorTab} id="home">
                                    <Row>
                                        {entityDetails?.entityDirectors && <>
                                            {Object.keys(entityDetails?.entityDirectors?.[activeDirectorTab])?.map((key, index) => {
                                                if(entityDirectorsData[key]){
                                                    return (
                                                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}> 
                                                            <div className='data-key ms-4'>
                                                                <span>
                                                                    {entityDirectorsData[key]}
                                                                </span>
                                                            </div>
                                                            <div className='data-value me-4'>
                                                                {rupeeSymbol?.includes(key) && '₹'}&nbsp;
                                                                {entityDetails?.entityDirectors[activeDirectorTab]?.[key] || '-'}
                                                                {reKycFieldData?.includes(key) && (entityDetails?.entityDirectors?.[activeDirectorTab]?.kycStatus !== 'VERIFIED') &&
                                                                    <ActionDropdown
                                                                        color={fieldStatusConstants?.[fieldStatus?.[key]]?.color}
                                                                        className={reKycFields?.some((field) => ((field?.data?.din === entityDetails?.entityDirectors?.[activeDirectorTab]?.din) && (field?.field === key))) ? fieldStatusConstants?.[fieldStatus?.[key]]?.className : fieldStatusConstants?.PENDING?.className}
                                                                        options={fieldVerificationDropdown}
                                                                        onClick={(action) => handleFieldAction(action, key, entityDetails?.entityDirectors?.[activeDirectorTab])}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                            {entityDetails?.entityDirectors?.[activeDirectorTab]?.kycStatus === 'VERIFIED' && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'> 
                                                <div className='data-key ms-4'>Bureau Report</div>
                                                {loading ? <div><Spinner size='sm' /></div> : <>
                                                    {entityDetails?.entityDirectors?.[activeDirectorTab]?.score ? <>
                                                        <div className='d-flex'>
                                                            {entityDetails?.entityDirectors?.[activeDirectorTab]?.score}&nbsp;<i onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeDirectorTab])} className='ri-eye-fill' />
                                                        </div>
                                                    </> : <Link onClick={fetchBureauOfDirector(entityDetails?.entityDirectors?.[activeDirectorTab])} className='me-4'>Fetch</Link>}
                                                </>}
                                            </div>}
                                            {entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                                <Accordion open={open} toggle={toggle} className='w-100'>
                                                    <AccordionItem>
                                                        <AccordionHeader targetId={'1'}>
                                                            Aadhaar 
                                                            <span onClick={() => handleImageExpand(entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc)} style={{marginLeft: "50%"}}>
                                                                <i className="ri-fullscreen-line" />
                                                            </span>
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId={`1`}>
                                                          <div className='w-100 mt-2'>
                                                            <iframe style={{width: "100%"}} src={entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc} alt="aadhaar PDF" />
                                                          </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </div>}
                                            {entityDetails?.entityDirectors[activeDirectorTab]?.selfie && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                                <Accordion open={open} toggle={toggle} className='w-100'>
                                                    <AccordionItem>
                                                        <AccordionHeader targetId={'1'}>
                                                            Selfie
                                                            <span onClick={() => handleImageExpand(entityDetails?.entityDirectors[activeDirectorTab]?.selfie)} style={{marginLeft: "50%"}}>
                                                                <i className="ri-fullscreen-line" />
                                                            </span>
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId={`1`}>
                                                          <div className='w-100 mt-2'>
                                                            <img className='w-100' src={entityDetails?.entityDirectors[activeDirectorTab]?.selfie} alt='Selfie' />
                                                          </div>
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                </Accordion>
                                            </div>}
                                        </>}
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                    }
                    {topDebtors?.length > 0 && 
                        <>
                        <hr />
                        <Row className='ps-4 pe-4'>
                            <Col style={{fontWeight: 'bold'}} className='mb-2'>Top Debtors</Col>
                            <Nav tabs>
                                {topDebtors?.map((debtor, index) => (
                                    <NavItem key={`topDebtors${index}`}>
                                        <NavLink 
                                            style={{color: activeTopDebtorsTab === index && 'green' }} 
                                            className={`${activeTopDebtorsTab === index && 'active'} clickable`} 
                                            onClick={toggleTopDebtorsTab(index)}
                                        >
                                          {debtor?.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTopDebtorsTab} className="p-3">
                                <TabPane tabId={activeTopDebtorsTab} id="topdebtors">
                                    <Row>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Type of loan</div>
                                            <div className='data-value'>{topDebtors?.[activeTopDebtorsTab]?.typeOfLoan}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Disbursment Date</div>
                                            <div className='data-value'>{formatDate3(topDebtors?.[activeTopDebtorsTab]?.disbursmentDate)}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Total principle amount</div>
                                            <div className='data-value'>₹ {parseInt(topDebtors?.[activeTopDebtorsTab]?.totalPrincipleAmount)?.toLocaleString()}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Total outstanding</div>
                                            <div className='data-value'>₹ {parseInt(topDebtors?.[activeTopDebtorsTab]?.totalOutstanding)?.toLocaleString()}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Total loan amount</div>
                                            <div className='data-value'>₹ {parseInt(topDebtors?.[activeTopDebtorsTab]?.totalLoanAmount)?.toLocaleString()}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>EMI amount</div>
                                            <div className='data-value'>₹ {parseInt(topDebtors?.[activeTopDebtorsTab]?.emiAmount)?.toLocaleString()}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Loan tenor</div>
                                            <div className='data-value'>{topDebtors?.[activeTopDebtorsTab]?.loanTenure} months</div>
                                        </div>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                        </>
                    }
                    {revenueBreakup?.length > 0 && 
                        <>
                        <hr />
                        <Row className='ps-4 pe-4'>
                            <Col style={{fontWeight: 'bold'}} className='mb-2'>Top Partners</Col>
                            <Nav tabs>
                                {revenueBreakup?.map((obj, index) => (
                                    <NavItem key={`topPartners${index}`}>
                                        <NavLink 
                                            style={{color: activeTopPartnersTab === index && 'green' }} 
                                            className={`${activeTopPartnersTab === index && 'active'} clickable`} 
                                            onClick={toggleTopPartnersTab(index)}
                                        >
                                          {obj?.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeTopPartnersTab} className="p-3">
                                <TabPane tabId={activeTopPartnersTab} id="toppartners">
                                    <Row>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Type of vehicle deployed</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.typeOfVehicleDeployed}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Manufacturer</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.manufacturer}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Number of vehicle deployed</div>
                                            <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.numberOfVehicleDeployed}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Avg. Kms driven/month</div>
                                        <div className='data-value'>{revenueBreakup?.[activeTopPartnersTab]?.avgKmDrivenPerMonth || '-'}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Contract Value</div>
                                            <div className='data-value'>₹ {revenueBreakup?.[activeTopPartnersTab]?.contractValue?.toLocaleString() || '-'}</div>
                                        </div>
                                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='data-key'>Avg. Revenue per Month</div>
                                            <div className='data-value'>₹ {revenueBreakup?.[activeTopPartnersTab]?.avgRevenuePerMonth?.toLocaleString() || '-'}</div>
                                        </div>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                        </>
                    }
                </CardBody>
            </Card>
            <DocModal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                size={'xl'}
                label={viewedDoc?.label}
                link={viewedDoc?.link}
            />
            <CreditScore
                isOpen={viewCreditReport}
                setIsOpen={setViewCreditReport}
                name={viewedDirector}
            />
            <BankStatement
                isOpen={viewBankStatement}
                setIsOpen={setViewBankStatement}
            />
            <NewAdditionalDoc
                isOpen={newAddDoc}
                toggle={toggleNewDoc}
            />
        </>
    )
}

export default Entity;
