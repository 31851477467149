import React, { useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';

const Create = (props) => {

  const { toaster, oemServer, permission, navigate } = useClass();
  const {isOpen, setIsOpen} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    website: '',
    contactEmail: '',
    contactNumber: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleTaskCreation = async () => {
    try{
      setIsLoading(true);
      if(validateForm()){
        const payload = {
          name: form?.name,
          code: form?.name.toUpperCase(),
          website:form?.website,
          contactEmail:form?.contactEmail,
          contactNumber:form?.contactNumber
      
        }
        const response = await oemServer.createManufacture(payload);
        setIsLoading(false);
        if(response?.data?.success){
          handleModelClose();
          if(permission?.MANUFACTURER?.DETAILS?.VIEW_MANUFACTURE_DETAILS){
            navigate(`/manufacturer/details/${response?.data?.data?.code}`);
            toaster.show(false, 'Manufacture created successfully');
          } else {
            toaster.show(false, 'Manufacture created successfully');
          }
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please fill all required fields');
      }
    } catch(error){
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });

    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create Manufacturer
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Name'}
                  required={true}
                  name={'name'}
                  value={form?.name}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>

              <div className='mt-4'>
                <TextInput 
                  label={'Website'}
                  required={true}
                  name={'website'}
                  value={form?.website}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              
              <div className='mt-4'>
                <TextInput 
                  label={'Contact Email'}
                  required={true}
                  name={'contactEmail'}
                  value={form?.contactEmail}
                  handleInput={handleInput}
                  
                />
              </div>

              <div className='mt-4'>
                <TextInput 
                  label={'Contact Number'}
                  required={true}
                  name={'contactNumber'}
                  value={form?.contactNumber}
                  handleInput={handleInput}
                  type={'number'}
                  maxLength={10}
                />
              </div>
    
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleTaskCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default Create;
