// yourSaga.js
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { Toaster } from '../../components/toaster/Toaster';
import { 
    updateApplicationAction, 
    updateApplicationActionSuccess, 
    updateApplicationActionError, 
    getApplicationFieldStatusError,
    getApplicationFieldStatus,
    getApplicationFieldStatusSuccess,
    updateSaasApplicationStatus,
    updateSaasApplicationStatusError,
    getAdditionalDocList,
    getAdditionalDocListError,
    getAdditionalDocListSuccess
} from './applicationAction';
import { ApiService } from '../../service/ApiService';
import { getApplicationLogs, getApplicationLogsError, getApplicationLogsSuccess, getCamReport, getCamReportError, getCamReportSuccess, getCustomerApplicationList, getCustomerApplicationListError, getCustomerApplicationListSuccess, getCustomerBasicDetails, getCustomerBasicDetailsError, getCustomerBasicDetailsSuccess, getEligibleLendersOfApplication, getEligibleLendersOfApplicationError, getEligibleLendersOfApplicationSuccess, getEntityDetails, getEntityDetailsError, getEntityDetailsSuccess, sendLinkForKYC, sendLinkForKYCError, sendLinkForKYCSuccess, updateCamReport, updateCamReportError, updateCamReportSuccess } from './customer';
import { getCustomerPayments,updateCustomerPayment, updateCustomerPaymentSuccess, getCustomerPaymentsError, getCustomerPaymentsSuccess } from './payment';
import { getCustomerEmiScheduleLoading, getCustomerEmiScheduleSuccess } from './emiSchedule';
const server = new ApiService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each application action
function* watchApplicationAction() {
    yield takeEvery(updateApplicationAction.type, takeActionOnApplication);
}

function* watchApplicationFieldStatus() {
    yield takeEvery(getApplicationFieldStatus.type, fetchApplicationFieldStatus);
}

function* watchCustomerApplicationList() {
    yield takeEvery(getCustomerApplicationList.type, fetchCustomerApplicationList);
}

function* watchCustomerBasicDetails() {
    yield takeEvery(getCustomerBasicDetails.type, fetchCustomerBasicDetails);
}

function* watchEntityDetails() {
    yield takeEvery(getEntityDetails.type, fetchEntityDetails);
}

function* watchCustomerpayments() {
    yield takeEvery(getCustomerPayments.type, fetchCustomerPayments);
}

function* watchUpdateCustomerPayments() {
    yield takeEvery(updateCustomerPayment.type, updateCustomerPayments);
}

function* watchCustomerEmiScedule() {
    yield takeEvery(getCustomerEmiScheduleLoading.type, fetchCustomerEmiSchedule);
}

function* watchSaasApplicationAction() {
    yield takeEvery(updateSaasApplicationStatus.type, sendSaasApplicationStatus);
}

function* watchEligibleLenders() {
    yield takeEvery(getEligibleLendersOfApplication.type, fetchEligibleLenders);
}

function* watchApplicationLogs() {
    yield takeEvery(getApplicationLogs.type, fetchApplicationLogs);
}

function* watchCamReport() {
    yield takeEvery(getCamReport.type, fetchCamReport);
}

function* watchCamReportUpdate() {
    yield takeEvery(updateCamReport.type, sendCamReport);
}

function* watchDirectorKYCLink() {
    yield takeEvery(sendLinkForKYC.type, triggerLinkForKYC);
}

function* watchAdditionalDocList() {
    yield takeEvery(getAdditionalDocList.type, fetchAdditionalDocList);
}





// updateCustomerPayments
const updateApplication = async (payload) => (
    await server.takeActionOnLoan(payload).then((response) => (response)).catch((error) => (error))
)

const fetchFieldStatusFromServer = async (payload) => (
    await server.getApplicationFieldStatus(payload).then((response) => (response)).catch((error) => (error))
)

const fetchCustomerApplicationListFromServer = async (payload) => (
    await server.getApplicationList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate, payload?.type).then((response) => (response)).catch((error) => (error))
)

const fetchCustomerBasicDetailsFromServer = async (payload) => (
    await server.getBasicDetailsOfCustomer(payload).then((response) => (response)).catch((error) => (error))
)

const fetchEntityDetailsFromServer = async (payload) => (
    await server.getCustomerEntityDetails(payload).then((response) => (response)).catch((error) => (error))
)

const fetchCustomerPaymentsFromServer = async (payload) => (
    await server.getPayments(payload).then((response) => (response)).catch((error) => (error))
)

const updatePayment = async (payload) => (
    await server.createNewPayment(payload).then((response) => (response)).catch((error) => (error))
)

const getEmiSchedule = async (payload) => (
    await server.getLoanEmiSchedule(payload.customerCode).then((response) => (response)).catch((error) => (error))
)

const sendSaasApplicationStatusToServer = async(payload) => (
    await server.updateSaasApplication(payload).then((response) => (response)).catch((error) => (error))
)

const fetchEligibleLendersFromServer = async (payload) => (
    await server.getEligibleLenders(payload).then((response) => (response)).catch((error) => (error))
)

const fetchApplicationLogsFromServer = async (payload) => (
    await server.getApplicationLogs(payload).then((response) => (response)).catch((error) => (error))
)

const fetchCamReportFromServer = async (payload) => (
    await server.getCamReport(payload).then((response) => (response)).catch((error) => (error))
)

const sendCamReportToServer = async (payload) => (
    await server.updateCamReport(payload).then((response) => (response)).catch((error) => (error))
)

const triggerLinkForKYCToServer = async (payload) => (
    await server.sendLinkForKYC(payload).then((response) => (response)).catch((error) => (error))
)

const fetchAdditionalDocListFromServer = async (payload) => (
    await server.getAdditionalDocList().then((response) => (response)).catch((error) => (error))
)





// Worker saga: will be fired on respective actions
function* takeActionOnApplication(action) {
    try {
        const response = yield call(updateApplication, action?.payload);
        if(response?.data?.success){
            yield put(updateApplicationActionSuccess(response?.data?.data));
            toaster.show(false, 'Successfully done');
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(updateApplicationActionError(error.message));
        toaster.show(true, error?.message);
    }
}

function* fetchApplicationFieldStatus(action) {
    try {
        const response = yield call(fetchFieldStatusFromServer, action?.payload);
        if(response?.data?.success){
            const {data} = response?.data;
            let payload = {};
            for(let i = 0; i < data?.length; i++){
                payload = {...payload, [data[i]?.fieldKey]: data?.[i]?.status}
            }
            yield put(getApplicationFieldStatusSuccess(payload));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getApplicationFieldStatusError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchCustomerApplicationList(action) {
    try {
        const response = yield call(fetchCustomerApplicationListFromServer, {page: action?.payload?.page, perPage: action?.payload?.perPage, search: action.payload.search, dateRange: action?.payload?.dateRange, type: action?.payload?.type});
        if(response?.data?.success){
            const { data, count } = response?.data;
            data.forEach((data, index) => {
                data.index = index + 1;;
            });
            yield put(getCustomerApplicationListSuccess({data, count}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getCustomerApplicationListError(error?.message));
    }
}

function* fetchCustomerBasicDetails(action) {
    try {
        const response = yield call(fetchCustomerBasicDetailsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getCustomerBasicDetailsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getCustomerBasicDetailsError(error?.message));
    }
}

function* fetchEntityDetails(action) {
    try {
        const response = yield call(fetchEntityDetailsFromServer, action?.payload);
        if(response?.data?.success){
            const data = response?.data?.data?.[0] || {};
            yield put(getEntityDetailsSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getEntityDetailsError(error?.message));
    }
}


function* fetchCustomerPayments(action) {
    try {
        const response = yield call(fetchCustomerPaymentsFromServer, action?.payload);
        if(response?.data?.success){
            const data = response?.data?.data || [];
            yield put(getCustomerPaymentsSuccess(data))
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put (getCustomerPaymentsError(error?.message))
    }
}


function* updateCustomerPayments(action) {
    try {
        const response = yield call(updatePayment, action?.payload);
        if(response?.data?.success){
            const data = response?.data?.data || {};
            yield put(updateCustomerPaymentSuccess(data))
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put (getCustomerPaymentsError(error?.message))
    }
}

function* fetchCustomerEmiSchedule(action) {
    try {
        const response = yield call(getEmiSchedule, action?.payload);
        if(response?.data?.success){
            const data = response?.data?.data || {};
            yield put(getCustomerEmiScheduleSuccess(data))
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put (getCustomerPaymentsError(error?.message))
    }
}

function* sendSaasApplicationStatus(action) {
    try {
        const response = yield call(sendSaasApplicationStatusToServer, action?.payload);
        if(response?.data?.success){
            window?.location?.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(updateSaasApplicationStatusError(error?.message));
    }
}

function* fetchEligibleLenders(action) {
    try {
        const response = yield call(fetchEligibleLendersFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getEligibleLendersOfApplicationSuccess(response.data.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(getEligibleLendersOfApplicationError(error?.message));
    }
}

function* fetchApplicationLogs(action) {
    try {
        const response = yield call(fetchApplicationLogsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getApplicationLogsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(getApplicationLogsError(error?.message));
    }
}

function* fetchCamReport(action) {
    try {
        const response = yield call(fetchCamReportFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getCamReportSuccess({data: response?.data?.data, code: action?.payload}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(getCamReportError(error?.message));
    }
}

function* sendCamReport(action) {
    try {
        const response = yield call(sendCamReportToServer, action?.payload);
        if(response?.data?.success) {
            yield put(updateCamReportSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(updateCamReportError(error?.message));
    }
}

function* triggerLinkForKYC(action) {
    try {
        const response = yield call(triggerLinkForKYCToServer, action?.payload);
        if(response?.data?.success){
            yield put(sendLinkForKYCSuccess(response?.data?.data));
            toaster.show(false, 'Link sent successfully');
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(sendLinkForKYCError(error?.message));
    }
}

function* fetchAdditionalDocList(action) {
    try {
        const response = yield call(fetchAdditionalDocListFromServer, {});
        if(response?.data?.success){
            let data = response?.data?.data?.map((obj) => ({...obj, label: obj?.fieldName, value: obj?.fieldCode}));
            yield put(getAdditionalDocListSuccess([...data, {label: 'Others', value: 'others'}]));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        toaster.show(true, error?.message);
        yield put(getAdditionalDocListError(error?.message));
    }
}


export default function* adminSaga(){
    yield all([
        fork(watchApplicationAction),
        fork(watchApplicationFieldStatus),
        fork(watchCustomerApplicationList),
        fork(watchCustomerBasicDetails),
        fork(watchEntityDetails),
        fork(watchCustomerpayments),
        fork(watchUpdateCustomerPayments),
        fork(watchCustomerEmiScedule),
        fork(watchSaasApplicationAction),
        fork(watchEligibleLenders),
        fork(watchApplicationLogs),
        fork(watchCamReport),
        fork(watchCamReportUpdate),
        fork(watchDirectorKYCLink),
        fork(watchAdditionalDocList)
    ])
};
