import { createSlice } from "@reduxjs/toolkit";

export const campaignTemplate = createSlice({
    name: 'campaignTemplate',
    initialState: {
        loading: false,
        error: null,
        detail:{},
        totalList: 0,
        templateList:[],
    },
    reducers: {
        getCampaignTemplates: (state, action) => {
            state.loading = true;
        },

        getCampaignTemplatesSuccess: (state, action) => {
            state.loading = false;
            state.templateList = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getCampaignTemplatesError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getCampaignTemplateDetailSuccess: (state, action) => {
            state.loading = false;
            state.detail = action?.payload;
        },
        getCampaignTemplateDetail: (state, action) => {
            state.loading = true;
        },

        createCampaignEmailTemplate: (state, action) => {
            state.loading = true
        },

        createCampaignEmailTemplateSuccess: (state, action) => {
            state.loading = false;
        },
        createCampaignWhatsAppTemplate: (state, action) => {
            state.loading = true
        },

        createCampaignWhatsAppTemplateSuccess: (state, action) => {
            state.loading = false;
        },
        createCampaignSMSTemplate: (state, action) => {
            state.loading = true
        },

        createCampaignSMSTemplateSuccess: (state, action) => {
            state.loading = false;
        }

    }
});





export const { 
    getCampaignTemplates,
    getCampaignTemplatesError,
    getCampaignTemplatesSuccess,
    getCampaignTemplateDetailSuccess,
    getCampaignTemplateDetail,
    createCampaignEmailTemplate,
    createCampaignEmailTemplateSuccess,
    createCampaignSMSTemplate,
    createCampaignSMSTemplateSuccess,
    createCampaignWhatsAppTemplate,
    createCampaignWhatsAppTemplateSuccess
} = campaignTemplate?.actions;


export const campaignTemplateReducer = campaignTemplate?.reducer;