import React from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { formatDate, formatDate3 } from '../../utils/helper';

const columns = (permission, handleSendToLender, applicationId, loanId) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>Lender Name</span>,
            selector: row => (row?.name || '-'),
            sortable: false,
            width: '300px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Approval Chance</span>,
            sortable: false,
            selector: (row) => {
                let number = Math.floor(Math.random() * 100);
                if(number >= 50 && number <= 69)
                    return <span className="badge badge-soft-info"> {`${number} %` || '-'} </span>;
                if(number >= 31 && number < 50)
                    return <span className="badge badge-soft-secondary"> {`${number} %` || '-'} </span>;
                if(number < 30)
                    return <span className="badge badge-soft-danger"> {`${number} %` || '-'} </span>;
                if(number > 69 && number <= 79)
                    return <span className="badge badge-soft-warning"> {`${number} %` || '-'} </span>;
                if(number > 79)
                    return <span className="badge badge-soft-success"> {`${number} %` || '-'} </span>;
                  
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {permission?.CUSTOMERS?.DETAILS?.SEND_TO_LENDER && <>
                            <Button size='sm' color='primary' onClick={() => handleSendToLender(applicationId, row?.code, loanId)}><i className='ri-send-plane-2-fill' /></Button>
                            <Button className='ms-2' size='sm' color='primary' onClick={() => {}}><i className='ri-edit-2-fill' /></Button>
                            </>
                        }
                    </div>
                );
            },
        },
    ];

    return data;
}

export default columns;
