import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helper';

const columns =  (permission) => {
    const data = [
    // {
    //     name: <span className='font-weight-bold fs-13'>SR No.</span>,
    //     selector: row => row.index,
    //     sortable: false
    // },
    {
        name: <span className='font-weight-bold fs-13'>Team Name</span>,
        selector: row => (permission?.TEAMS?.DETAILS?.VIEW_TEAM_DETAILS ? <Link to={`/teams/details/${row?.id}`}>{row.name}</Link> : row?.name),
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Team Code</span>,
        selector: row => row.code,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Owner</span>,
        selector: row => row.teamOwner,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Is Active</span>,
        sortable: false,
        selector: (row) => {
            switch (`${row?.isActive}`) {
                case "false":
                    return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                case "true":
                    return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                default:
                    return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
            }
        },
    },
    {
        name: <span className='font-weight-bold fs-13'>Created Date</span>,
        selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
        sortable: false
    },
];

    return data;
}

export default columns;
