import React from 'react';
import { Link } from 'react-router-dom';

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row.srNo,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => (<Link to={`/details#${row.customerCode}`}>{row.name}</Link>),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Customer Code</span>,
            selector: row => row.customerCode,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Application ID</span>,
            selector: row => (<Link to="#!">{row.applicationID}</Link>),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Product</span>,
            selector: row => row.product,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Mobile</span>,
            selector: row => row.mobile,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Created Date</span>,
            selector: row => row.createDate,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row.status) {
                    case "Re-open":
                        return <span className="badge badge-soft-info"> {row.status} </span>;
                    case "On-Hold":
                        return <span className="badge badge-soft-secondary"> {row.status} </span>;
                    case "Closed":
                        return <span className="badge badge-soft-danger"> {row.status} </span>;
                    case "In-Progress":
                        return <span className="badge badge-soft-warning"> {row.status} </span>;
                    case "Open":
                        return <span className="badge badge-soft-primary"> {row.status} </span>;
                    case "New":
                        return <span className="badge badge-soft-success"> {row.status} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row.status} </span>;
                }
            },
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>Priority</span>,
        //     sortable: false,
        //     selector: (row) => {
        //         switch (row.priority) {
        //             case "High":
        //                 return <span className="badge bg-danger"> {row.priority} </span>;
        //             case "Medium":
        //                 return <span className="badge bg-info"> {row.priority} </span>;
        //             case "Low":
        //                 return <span className="badge bg-success"> {row.priority} </span>;
        //             default:
        //                 return <span className="badge bg-danger"> {row.priority} </span>;
        //         }
        //     },
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Action</span>,
        //     sortable: false,

        //     row: () => {
        //         return (
        //             <UncontrolledDropdown className="dropdown d-inline-block">
        //                 <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
        //                     <i className="ri-more-fill align-middle"></i>
        //                 </DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <DropdownItem href="#!"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
        //                     <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
        //                     <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         );
        //     },
        // },
    ];

export default columns;
