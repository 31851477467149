import React from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { formatDate, formatDate3 } from '../../utils/helper';
import useClass from '../../hooks/useClass';

const Columns = () => {

    const { permission } = useClass();

    const data = [
        {
            name: <span className='font-weight-bold fs-13'>Lender Name</span>,
            selector: row => (row?.name || '-'),
            sortable: false,
            width: '300px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Child Loan ID</span>,
            selector: row => (row?.loanUid || '-'),
            sortable: false,
            width: '200px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Lender Loan ID</span>,
            selector: row => (row?.lenderLoanId || '-'),
            sortable: false,
            width: '200px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Approved Amount(Rs)</span>,
            selector: row => row?.amountApproved ? (parseInt(row?.amountApproved).toLocaleString()) : '-',
            sortable: false,
            width: '200px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Amount Disbursed(Rs)</span>,
            selector: row => row?.amountDisbursed ? (parseInt(row?.amountDisbursed).toLocaleString()) : '-',
            sortable: false,
            width: '200px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.statusCode) {
                    case "Re-open":
                        return <span className="badge badge-soft-info"> {row?.statusCode || '-'} </span>;
                    case "On-Hold":
                        return <span className="badge badge-soft-secondary"> {row?.statusCode || '-'} </span>;
                    case "Closed":
                        return <span className="badge badge-soft-danger"> {row?.statusCode || '-'} </span>;
                    case "INPROGRESS":
                        return <span className="badge badge-soft-warning"> {row?.statusCode || '-'} </span>;
                    case "Open":
                        return <span className="badge badge-soft-primary"> {row?.statusCode || '-'} </span>;
                    case "COMPLETE":
                        return <span className="badge badge-soft-success"> {row?.statusCode || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row?.statusCode || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Created Date</span>,
            selector: row => (formatDate3(row?.createdAt) || '-'),
            sortable: false,
            width: '150px',
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {   <>
                            <Button className='ms-2' size='sm' color='primary' onClick={() => {}}><i className='ri-edit-2-fill' /></Button>
                            </>
                        }
                    </div>
                );
            },
        },
    ];

    return data;
}

export default Columns;
