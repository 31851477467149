import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import TextInput from '../../../components/text-input/TextInput';
import useClass from '../../../hooks/useClass';
import Dropdown from '../../../components/Dropdown/Dropdown.js'
import { createCampaignEmailTemplate, createCampaignSMSTemplate, createCampaignWhatsAppTemplate } from '../../../store/campaign/template';
import { messageType, whatsAppButtonType, whatsAppHeaderType } from '../../../constants/constants';

const Create = ({ isOpen, setIsOpen }) => {
  const [activeTab, setActiveTab] = useState('email');
  const [isLoading, setIsLoading] = useState(false);
  const [showButtonFields, setShowButtonFields] = useState(false);
  const {dispatch, toaster} = useClass();
  const [emailForm, setEmailForm] = useState({
    emailSubject: '',
    emailBody: '',
    description: '',
    status: 'Active',
    emailName: '',
    emailType: '',
    emailFiles: []
  });


  const [whatsAppForm, setWhatsAppForm] = useState({
    body:'',
    templateName:'',
    status:'Active',
    language:'en',
    description:'',
    footer:'',
    headerType:'',
    headerContent:'',
    buttonContent:'',
    buttonType:'',
    buttonLabel:'',
    buttonValue:'',
    files:[]
  });


  const [smsForm, setSmsForm] = useState({
     smsText:'',
     description:'',
     dltId:'',
     senderId:'',
     smsType:'',
  })

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // handle email input field
  const handleFileChange = (e) => {
    setEmailForm((prevState) => ({
        ...prevState,
        emailFiles: Array.from(e.target.files) // Convert FileList to an array
    }));
};

const handleWhatsAppFile = (e) => {
  setWhatsAppForm((prevState) => ({
    ...prevState,
    files: Array.from(e.target.files) 
}));
}

  const handleEmailInput = (key, value) => {
    if(key === 'emailType'){
      setEmailForm((prev) => ({
        ...prev,
        [key]: value?.value,
      }))
    }
    else{
    setEmailForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }
};

  const handleWhatsappInput = (key, value) => {
    if(key === 'buttonType' || key == 'headerType'){
      setWhatsAppForm((prev) => ({
        ...prev,
        [key]: value?.value,
      }))
    }
    else{
    setWhatsAppForm((prev) => ({
        ...prev,
        [key]: value
    }));
   }
  }

  const handeSMsInput = (key, value) => {
    if(key === 'smsType'){
      setSmsForm((prev) => ({
        ...prev,
        [key]: value?.value,
      }))
    }
    else{
    setSmsForm((prev) => ({
      ...prev,
      [key]: value
    }))
   }
  }


  const handleSubmit = (e, formType) => {
    e.preventDefault();
    try {
        const formData = new FormData();

        // Handling Email Form
        if (formType === 'email' && validateEmailForm(emailForm)) {
            for (const key in emailForm) {
                if (Array.isArray(emailForm[key])) {
                    emailForm[key].forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, emailForm[key]);
                }
            }
            dispatch(createCampaignEmailTemplate(formData));
        }
        // Handling WhatsApp Form
        else if (formType === 'whatsapp' && validateWhatsAppForm(whatsAppForm)) {
            for (const key in whatsAppForm) {
                if (Array.isArray(whatsAppForm[key])) {
                    whatsAppForm[key].forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, whatsAppForm[key]);
                }
            }
            dispatch(createCampaignWhatsAppTemplate(formData)); // Adjust this to your dispatch action for WhatsApp
        }
        // Handling  SMS Form
        else if (formType === 'sms' && validateSmsForm(smsForm)) {
             dispatch(createCampaignSMSTemplate(smsForm)); // Adjust this to your dispatch action for SMS
        }
        else {
            throw new Error('Please fill the required fields.');
        }
   } catch (error) {
        setIsLoading(false);
        toaster.show(true, error?.message);
   }
 
  };


  const validateEmailForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
        if(typeof(payload[key]) == 'object') return true
      return payload.hasOwnProperty(key) && payload[key].trim() !== '';
    });
    return result;
  }

  const validateWhatsAppForm = (payload) => {
    const requiredFields = ['body', 'templateName', 'language', 'description'];
    const result = requiredFields.every((field) => {
        return payload.hasOwnProperty(field) && payload[field].trim() !== '';
    });

    return result;
};

const validateSmsForm = (payload) => {
  const result = Object.keys(payload).every((key) => {
    if(typeof(payload[key]) == 'object') return true
  return payload.hasOwnProperty(key) && payload[key].trim() !== '';
});
return result;
}

console.log("emailForm",smsForm)

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>
      <ModalHeader >Create Template</ModalHeader>
      <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
      <ModalBody>
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav tabs>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 'email' })} onClick={() => toggleTab('email')}>
                        Email
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 'whatsapp' })} onClick={() => toggleTab('whatsapp')}>
                        WhatsApp
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className={classnames({ active: activeTab === 'sms' })} onClick={() => toggleTab('sms')}>
                        SMS
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="email">
                    <Form className='p-3' onSubmit={(e) =>handleSubmit(e,'email')}>
                      <FormGroup>
                        <TextInput 
                        label={'Email Subject'}
                        value={emailForm?.emailSubject}
                         required={true}
                         type={'emailSubject'}
                         name={'emailSubject'}
                         handleInput={handleEmailInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                        label={'Email Body'}
                        value={emailForm?.emailBody}
                         required={true}
                         type={'text'}
                         name={'emailBody'}
                         handleInput={handleEmailInput}
                        />
                      </FormGroup>
                       <FormGroup>
                       <div className='heading'>Upload Files</div>
                        <Input type="file"
                          name="emailFiles" multiple
                           onChange={handleFileChange} />
                     </FormGroup>
                     <FormGroup>
                        <TextInput 
                        label={'Email Name '}
                        value={emailForm?.emailName}
                         required={true}
                         type={'text'}
                         name={'emailName'}
                         handleInput={handleEmailInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                        label={'Email Description'}
                        value={emailForm?.description}
                         required={true}
                         type={'textarea'}
                         name={'description'}
                         handleInput={handleEmailInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Dropdown
                        label={'Select  email Type'}
                        value={emailForm?.emailType?.label}
                        required={true}
                        name={'emailType'}
                        handleInput={handleEmailInput}
                        options ={messageType?.map((type) => ({label: type?.label, value: type?.value}))}
                        />
                      </FormGroup>
                      <Button className='w-100 mt-2' color="primary" type='submit'>Create Email</Button>
                    </Form>
                  </TabPane>
                  <TabPane tabId="whatsapp">
                    <Form className='p-3' onSubmit={(e) =>handleSubmit(e,'whatsapp')}>
                    <FormGroup>
                     <FormGroup>
                        <Dropdown
                             label={'Select Header Type'}
                             value={whatsAppForm?.headerType?.label}
                             name={'headerType'}
                             handleInput={handleWhatsappInput}
                             options ={whatsAppHeaderType?.map((type) => ({label: type?.label, value: type?.value}))}
                          />
                      </FormGroup>
                      { whatsAppForm?.headerType === "TEXT" && <FormGroup>
                        <TextInput 
                        label={'Header Content'}
                        value={whatsAppForm?.headerContent}
                         type={'text'}
                         name={'headerContent'}
                         handleInput={handleWhatsappInput}
                        />
                      </FormGroup>}
                      { (whatsAppForm?.headerType == "DOCUMENT") && <FormGroup>
                       <div for="headerContent">Header Content</div>
                        <Input type="file"
                          name="headerContent" multiple
                           onChange={handleWhatsAppFile} />
                     </FormGroup>}
                        <TextInput 
                        label={'Body'}
                        value={whatsAppForm?.body}
                         required={true}
                         type={'text'}
                         name={'body'}
                         handleInput={handleWhatsappInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                        label={'Template Name'}
                        value={whatsAppForm?.templateName}
                         required={true}
                         type={'text'}
                         name={'templateName'}
                         handleInput={handleWhatsappInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                        label={'Language'}
                        value={whatsAppForm?.language}
                        required={true}
                        type={'text'}
                        name={'language'}
                        handleInput={handleWhatsappInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                        label={'Description'}
                        value={whatsAppForm?.description}
                         required={true}
                         type={'text'}
                         name={'description'}
                         handleInput={handleWhatsappInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                        label={'Footer'}
                        value={whatsAppForm?.footer}
                         type={'text'}
                         name={'footer'}
                         handleInput={handleWhatsappInput}
                        />
                      </FormGroup>
                      <Col>
                      <Button 
                       className='w-100'
                       color="primary"
                        outline
                        type="button" 
                       onClick={() => setShowButtonFields(!showButtonFields)}
                       >
                    {showButtonFields ? 'Remove Button' : 'Add Button Fields'}
                   </Button>
                      </Col>
                {showButtonFields && (
                    <>
                        <FormGroup>
                            <TextInput 
                                label={'Button Content'}
                                value={whatsAppForm.buttonContent}
                                type={'text'}
                                name={'buttonContent'}
                                handleInput={handleWhatsappInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Dropdown
                             label={'Select Button Type'}
                             value={whatsAppForm?.buttonType?.label}
                             required={true}
                             name={'buttonType'}
                             handleInput={handleWhatsappInput}
                             options ={whatsAppButtonType?.map((type) => ({label: type?.label, value: type?.value}))}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput 
                                label={'Button Label'}
                                value={whatsAppForm.buttonLabel}
                                type={'text'}
                                name={'buttonLabel'}
                                handleInput={handleWhatsappInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput 
                                label={'Button Value'}
                                value={whatsAppForm.buttonValue}
                                type={'text'}
                                name={'buttonValue'}
                                handleInput={handleWhatsappInput}
                            />
                        </FormGroup>
                    </>
                )}
                      <Button className='w-100 mt-2' color="primary" type='submit'>Create Whatsapp</Button>
                    </Form>
                  </TabPane>
                  <TabPane tabId="sms">
                    <Form className='p-3' onSubmit={(e) =>handleSubmit(e,'sms')}>
                      <FormGroup>
                        <TextInput 
                                label={'SMS Text'}
                                value={smsForm.smsText}
                                type={'textarea'}
                                required={true}
                                name={'smsText'}
                                handleInput={handeSMsInput}
                            />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                                label={'SMS Description'}
                                value={smsForm.description}
                                type={'textarea'}
                                required={true}
                                name={'description'}
                                handleInput={handeSMsInput}
                            />
                        
                      </FormGroup>
                      <FormGroup>
                         <TextInput 
                                label={'DLT ID'}
                                value={smsForm.dltId}
                                type={'textarea'}
                                maxLength={19}
                                required={true}
                                name={'dltId'}
                                handleInput={handeSMsInput}
                            />
                      </FormGroup>
                      <FormGroup>
                        <TextInput 
                                label={'Sender Id'}
                                value={smsForm.senderId}
                                type={'textarea'}
                                required={true}
                                name={'senderId'}
                                handleInput={handeSMsInput}
                            />
                      </FormGroup>
                      <FormGroup>
                      <Dropdown
                        label={'Select  SMS Type'}
                        value={emailForm?.emailType?.label}
                        required={true}
                        name={'smsType'}
                        handleInput={handeSMsInput}
                        options ={messageType?.map((type) => ({label: type?.label, value: type?.value}))}
                        />
                      </FormGroup>
                      <Button className='w-100 mt-2' color="primary" type='submit'>Create SMS</Button>
                    </Form>
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default Create;
