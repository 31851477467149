import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../components/text-input/TextInput";
import Dropdown from "../../../components/Dropdown/Dropdown";
import LoadingButton from "../../../components/Common/LoadingButton";
import AsyncDropdown from "../../../components/Dropdown/AsyncDropdown";
import { getSegmentList } from "../../../store/campaign/segment";
import { useSelector } from "react-redux";
import { channel, schedule, timeArray } from "../../../constants/constants";
import { getCampaignTemplates } from "../../../store/campaign/template";
import { createCampaignFLow } from "../../../store/campaign/campaignFlow";

const Create = (props) => {
  const { adminServer, toaster, dispatch } = useClass();
  const { isOpen, setIsOpen } = props;
  const [loading, setIsLoading] = useState(false);
  const {list} = useSelector((state)=> state.campaignSegment);
  const {templateList} = useSelector((state)=> state.campaignTemplate);
  const [campaignForm, setCampaignForm] = useState({
    name: "",
    description: "",
    segmentId: "",
    templateId: "",
  });
  const [campaignFlowForm, setCampaignFlowForm] = useState({
    channel: "",
    schedule: "",
    triggerDate: "",
    triggerTime: "",
  });

  const handleModelClose = () => {
    setIsOpen(false);
  };



  const handleCampaignInput = (key, value) => {
    if(key === "templateId"){

        setCampaignForm((prev)=> ({
            ...prev,
            templateId: value?.value
        }))

        setCampaignFlowForm((prev)=> ({
            ...prev,
            channel: value?.type
        }))
    }
    else if(key === "segmentId"){
        setCampaignForm((prev)=> ({
            ...prev,
            segmentId: value?.value
        }))
    }
    else{
    setCampaignForm((prev) => ({
        ...prev,
        [key]: value
      }))
    }
  }

  const handleCampaignFlowInput = (key, value) => {
    if(key === 'channel' || key == 'schedule' || key == 'triggerTime'){
        setCampaignFlowForm((prev) => ({
          ...prev,
          [key]: value?.value,
        }))
      }
    else{
        setCampaignFlowForm((prev) => ({
        ...prev,
        [key]: value
      }))
    }
  }


  useEffect(() => {
    dispatch(getSegmentList());
    dispatch(getCampaignTemplates());
}, [])

  const fetchSegmentList = (search='', callback) => {
    dispatch(getSegmentList({search, callback}))
  }

  const fetchTemplateList = (search='', callback) => {
    dispatch(getCampaignTemplates({search, callback}))
  }


  const handleCampaignSubmit = async () => {
  try {
    if(validateForm(campaignForm) || validateForm(campaignFlowForm)){
        const payload =  {
         campaignData: campaignForm,
         campaignActionData: campaignFlowForm
        }   
       dispatch(createCampaignFLow(payload))
     }
     else{
         throw new Error('Please fill the required fields.');
     }
  } catch (error) {
    setIsLoading(false);
    toaster.show(true, error?.message);
}
     
  }

  const validateForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
       if(typeof payload[key] =='number') return 
    return payload.hasOwnProperty(key) && payload[key].trim() !== '';
  });
  return result;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Campaign
          </h5>
          <button
            type="button"
            onClick={handleModelClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <TextInput
                label="Name"
                required={true}
                name="name"
                value={campaignForm.name}
                  handleInput={handleCampaignInput}
                type="text"
              />
            </Col>
            <Col>
              <TextInput
                label="Description"
                required={true}
                name="description"
                value={campaignForm.description}
                  handleInput={handleCampaignInput}
                type="text"
              />
            </Col>
          </Row>
          <div className="mt-4">
            <Row>
              <Col>
               <AsyncDropdown
                 label={'Select Segment'}
                 name={'segmentId'}
                 value ={campaignForm.segmentId?.value}
                 handleInput={handleCampaignInput}
                 required={true}        
                 returnsObj={true}
                 defaultOptions ={list}
                 loadOptions ={fetchSegmentList}
               />
              </Col>
              <Col>
              <AsyncDropdown
                 label={'Select Template'}
                 name={'templateId'}
                 value ={campaignForm.templateId?.value}
                 handleInput={handleCampaignInput}
                 required={true}        
                 returnsObj={true}
                 defaultOptions ={templateList}
                 loadOptions ={fetchTemplateList}
               />
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col>
                <Dropdown
                  label={"Select Channel"}
                  value={campaignFlowForm?.channel?.value}
                  required={true}
                  name={"channel"}
                  options={channel?.map((type) => ({label: type?.label, value: type?.value}))}
                  handleInput={handleCampaignFlowInput}
                />
              </Col>
              <Col>
                <Dropdown
                  label={"Select Trigger Time"}
                  value={campaignFlowForm?.triggerTime?.value}
                  required={true}
                  name={"triggerTime"}
                  options={timeArray?.map((time) => ({label: time?.label, value: time?.value}))}
                  handleInput={handleCampaignFlowInput}
                />
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <Row>
              <Col>
                <TextInput
                  label="Trigger Date"
                  required={true}
                  name="triggerDate"
                //   maxLength={today}
                  value={campaignFlowForm.triggerDate}
                  handleInput={handleCampaignFlowInput}
                  type="date"
                />
              </Col>
              <Col>
                <Dropdown
                  label={"Select Schedule"}
                  value={campaignFlowForm?.schedule?.value}
                  required={true}
                  name={"schedule"}
                  options={schedule?.map((time) => ({label: time?.label, value: time?.value}))}
                  handleInput={handleCampaignFlowInput}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleModelClose}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <LoadingButton
            buttonContent={"Create"}
            isLoading={loading}
            color={"success"}
            onClick={handleCampaignSubmit}
          />
        </div>
      </Modal>
    </>
  );
};

export default Create;
