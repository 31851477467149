import { useState } from "react";
import { Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";

const Table = ({ 
  data, 
  loading, 
  pagination, 
  setPagination, 
  columns, 
  paginationTotalRows=20, 
  showPagination=true,
  onRowClick=null,
  expandableRows=false,
  ExpandedComponent=<></>
}) => {

  const [expandedRow, setExpendedRow] = useState(null);

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      page: page,
    }));
  };

  const handleRowPerPageChange = (row) => {
    setPagination((prev) => ({
      ...prev,
      perPage: row,
    }));
  };

  const handleRowClicked = (data) => {
    if(expandedRow === data.id){
      setExpendedRow(null);
    } else {
      setExpendedRow(data?.id);
    }
    if(onRowClick){
      onRowClick(data)
    }
  }

  return (
    <DataTable
      // title={label}
      columns={columns}
      data={data}
      pagination={showPagination}
      paginationPerPage={pagination?.perPage}
      paginationRowsPerPageOptions={[5, 10, 20]}
      onChangePage={handlePageChange}
      progressPending={loading}
      paginationServer={showPagination}
      paginationTotalRows={paginationTotalRows}
      onChangeRowsPerPage={handleRowPerPageChange}
      onRowClicked={handleRowClicked}
      expandableRows={expandableRows}
      expandableRowExpanded={(row) => row.id === expandedRow}
      expandableRowsComponent={({data}) => <ExpandedComponent data={data} />}
      highlightOnHover
      pointerOnHover
    />
  );
};

export default Table;
