import { createSlice } from "@reduxjs/toolkit";

export const customerApplication = createSlice({
    name: 'customerApplication',
    initialState: {
        loading: false,
        error: null,
        list: [],
        totalList: 0,
        basicDetails: {},
        entityDetails: {},
        applicationDetails: {}
    },
    reducers: {
        getCustomerApplicationList: (state, action) => {
            state.loading = true;
        },
        getCustomerApplicationListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },
        getCustomerApplicationListError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getCustomerBasicDetails: (state, action) => {
            state.loading = true;
        },
        getCustomerBasicDetailsSuccess: (state, action) => {
            state.loading = false;
            state.basicDetails = action?.payload
        },
        getCustomerBasicDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        getEntityDetails: (state, action) => {
            state.loading = true;
        },
        getEntityDetailsSuccess: (state, action) => {
            state.loading = false;
            state.entityDetails = action?.payload;
        },
        getEntityDetailsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});

export const eligibleLendersOfApplication = createSlice({
    name: 'eligibleLenders',
    initialState: {
        loading: false,
        error: null,
        list: []
    },
    reducers: {
        getEligibleLendersOfApplication: (state, action) => {
            state.loading = true;
            state.error = null;
            state.list = [];
        },
        getEligibleLendersOfApplicationSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload;
        },
        getEligibleLendersOfApplicationError: (state, action) => {
            state.loading = false;
            state.error = action?.payload
        }
    }
});

export const applicationLogs = createSlice({
    name: 'applicationLogs',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        getApplicationLogs: (state, action) => {
            state.loading = true;
            state.error = null;
            state.data = null
        },
        getApplicationLogsSuccess: (state, action) => {
            state.loading = false;
            state.data = action?.payload;
        },
        getApplicationLogsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

export const camReport = createSlice({
    name: 'camReport',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        getCamReport: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getCamReportSuccess: (state, action) => {
            state.loading = false;
            state.data = {...state?.data, [action?.payload?.code]: action?.payload?.data}
        },
        getCamReportError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },
        updateCamReport: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        updateCamReportSuccess: (state, action) => {
            state.loading = false;
        },
        updateCamReportError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
})

export const sendLinkToDirector = createSlice({
    name: 'sendLinkToDirector',
    initialState: {
        loading: false,
        error: null,
        data: null
    },
    reducers: {
        sendLinkForKYC: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        sendLinkForKYCSuccess: (state, action) => {
            state.loading = false;
        },
        sendLinkForKYCError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})






export const { 
    getCustomerApplicationList,
    getCustomerApplicationListSuccess,
    getCustomerApplicationListError,
    getCustomerBasicDetails,
    getCustomerBasicDetailsSuccess,
    getCustomerBasicDetailsError,
    getEntityDetails,
    getEntityDetailsSuccess,
    getEntityDetailsError,
} = customerApplication?.actions;

export const {
    getEligibleLendersOfApplication,
    getEligibleLendersOfApplicationSuccess,
    getEligibleLendersOfApplicationError
} = eligibleLendersOfApplication?.actions;

export const {
    getApplicationLogs,
    getApplicationLogsSuccess,
    getApplicationLogsError
} = applicationLogs?.actions;

export const  {
    getCamReport,
    getCamReportSuccess,
    getCamReportError,
    updateCamReport,
    updateCamReportSuccess,
    updateCamReportError
} = camReport?.actions;

export const {
    sendLinkForKYC,
    sendLinkForKYCSuccess,
    sendLinkForKYCError
} = sendLinkToDirector?.actions


export const customerApplicationReducer = customerApplication?.reducer;
export const eligibleLendersReducer = eligibleLendersOfApplication?.reducer;
export const applicationLogsReducer = applicationLogs?.reducer;
export const camReportReducer = camReport?.reducer;
