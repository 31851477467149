import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        // {
        //     name: <span className='font-weight-bold fs-13'>SR No.</span>,
        //     selector: row => row?.index || '-',
        //     sortable: false
        // },
        {
            name: <span className='font-weight-bold fs-13'>Charger Code</span>,
            // selector: row => (permission?.CHARGER?.DETAILS?.VIEW_CHARGER_DETAILS ? <Link to={`/charger/details/${row?.code}`}>{row?.code || '-'}</Link> : row?.code),
            selector: row => (permission?.CHARGER?.DETAILS?.VIEW_CHARGER_DETAILS ? <Link to={`/charger/details/${row?.code}`}>{row?.code || '-'}</Link> : row?.code),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Station Name</span>,
            selector: row => row?.stationName || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Charger Type</span>,
            selector: row => row?.chargerType || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Rated Capacity</span>,
            selector: row => row?.ratedCapacity || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Power Type</span>,
            selector: row => row?.powerType || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Tariff Rate</span>,
            selector: row => row?.tariffRate || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Created Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        }

    ];

    return data;
}

export default columns;
