import React, { useEffect, useState } from 'react';
import { Row, Container, Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import { manufactureData } from '../../../utils/constants';


const Details = () => {

  const { toaster,oemServer, } = useClass();
  const { manufactureId } = useParams();
  const [manufactureDetails, setManufactureDetails] = useState({});



  useEffect(() => {
    getManufactureDetails();
  }, []);



  const getManufactureDetails = async () => {
    try {
      const response = await oemServer.getManufactureById(manufactureId);
      if(response?.data?.success){
        setManufactureDetails(response?.data?.data);
        return
      }
      
      toaster.show(true, response?.data?.message);
    } catch(error){
      toaster.show(true, error?.message);
    }
  }


  return (
    <>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="manufacturer" breadcrumbItem="Details" />
          <Row>
            <Col xl={12}>
              <Card className='common-card-style'>
                <CardHeader>
                  <div className='d-flex gap-4 justify-content-between'>
                    <div className='section-heading'>Details</div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    {Object?.keys(manufactureData)?.map((key, index) => (
                      <div key={index} className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                        <Row className='data-key ms-4'>{manufactureData?.[key]}</Row>
                        <Row className='data-value me-4'>{manufactureDetails?.[key] || '-'}</Row>
                      </div>
                    ))}
                 
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='d-flex justify-content-center gap-4'>
                {/* {(permission?.TASK?.DETAILS?.UPDATE_TASK_DETAILS || permission?.TASK?.DETAILS?.UPDATE_OWN_TASK_DETAIL) && 
                  // <LoadingButton color={'success'} onClick={handleTaskUpdate} buttonContent={'Update'} isLoading={isLoading}/>
                } */}
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  )
}

export default Details;
