import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Segments from '../../Pages/Campaign/Segment/Segments';
import Details from '../../Pages/Campaign/Segment/Detail';

const Segment = () => {
  return (
    <Routes>
        <Route path='/list' element={<Segments/>} />
        <Route path='/details/:segmentId' element={<Details />} />
    </Routes>
  )
}

export default Segment;