import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import TextInput from "../../../components/text-input/TextInput";
import useClass from "../../../hooks/useClass";
import {
  addLenderNewAddress,
  getLenderAddress,
  getLenderDetails,
} from "../../../store/admin/adminData";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { indianStatesAndUTs } from "../../../constants/constants";
import { useSelector } from "react-redux";
import LoadingButton from "../../../components/Common/LoadingButton";

const CreateLenderAddress = ({ isOpen, toggle, lenderCode }) => {
  const { lenderAddress, loading } = useSelector((store) => store?.lender);
  const [formData, setFormData] = useState({
    CGST: "",
    IGST: "",
    SGST: "",
    address: "",
    gstNumber: "",
    lenderCode: lenderCode,
    pinCode:"",
    contactPerson: "",
  });
  const { dispatch } = useClass();

  useEffect(() => {
    findStateInAddress();
    getPincodeFromAddress()
  }, [lenderAddress]);

  const handleModelClose = () => {
    toggle(false);
  };

  const handleChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value
    }))

    if (key === "gstNumber" && value.length === 15) {
      dispatch(getLenderAddress({ gstNumber: value }));
    }
  };

  const addNewLenderAddress = async () => {
    dispatch(addLenderNewAddress({ lenderCode, payload: formData }));
  };

  const findStateInAddress = () => {
    for (const state of indianStatesAndUTs) {
      if (lenderAddress?.lenderAddress?.includes(state.label)) {
        setFormData((prev) => ({ 
          ...prev, 
          state: state?.label,
          address:lenderAddress?.lenderAddress 
        }));
      }
    } 
  };


  const getPincodeFromAddress = () => {
    const pincodePattern = /\b\d{6}\b/; 
    const match = lenderAddress?.lenderAddress?.match(pincodePattern);
    return match ?  setFormData((prev)=> ({...prev, pinCode:match[0] })): null; 
  };

  return (
    <Modal isOpen={isOpen} toggle={handleModelClose} centered>
      <ModalHeader toggle={handleModelClose}>Add New Address</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6} className="mb-2">
            <TextInput
              name="gstNumber"
              label="GST Number"
              value={formData.gstNumber}
              required={true}
              maxLength={15}
              handleInput={handleChange}
            />
          </Col>
          <Col md={6} className="mb-2">
            <TextInput
              name="pinCode"
              label="Pincode"
              value={formData.pinCode}
              required={true}
              maxLength={15}
              handleInput={handleChange}
            />
          </Col>
          <Col md={6}>
            <TextInput
              name="address"
              label="Address"
              value={formData.address}
              required={true}
              handleInput={handleChange}
            />
          </Col>
          <Col md={6} className="mb-2">
            <TextInput
              name="contactPerson"
              label="Contact Number"
              value={formData.contactPerson}
              required={true}
              handleInput={handleChange}
              type="number"
              maxLength={10}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleModelClose}>
          Close
        </Button>
        <LoadingButton color="success" buttonContent="Submit" isLoading={loading} onClick={addNewLenderAddress} />
      </ModalFooter>
    </Modal>
  );
};

export default CreateLenderAddress;
