import { createSlice } from "@reduxjs/toolkit";

export const campaignFlow = createSlice({
    name: 'campaignFlow',
    initialState: {
        loading: false,
        error: null,
        detail:{},
        totalList: 0,
        list:[],
    },
    reducers: {
        createCampaignFLow: (state, action) => {
            state.loading = true;
        },

        createCampaignFLowSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload
        },
        createCampaignFLowError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        },

        getCampaignFlowList: (state, action) => {
            state.loading = true;
        },

        getCampaignFlowListSuccess: (state, action) => {
            state.loading = false;
            state.list = action?.payload?.data;
            state.totalList = action?.payload?.count;
        },

        getCampaignFlowDetails: (state, action) => {
            state.loading = true;
        },

        getCampaignFlowDetailsSuccess: (state, action) => {
            state.loading = false;
            state.detail = action?.payload;
        },


    
    }
});





export const { 
    createCampaignFLow,
    createCampaignFLowSuccess,
    getCampaignFlowList,
    getCampaignFlowListSuccess,
    getCampaignFlowDetails,
    getCampaignFlowDetailsSuccess,
    createCampaignFLowError
} = campaignFlow?.actions;


export const campaignFlowReducer = campaignFlow?.reducer;